import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { theme } from "@polifonia/theme";
import useSwitch from "@react-hook/switch";
import { useNavigate } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import { useSnackbar } from "notistack";
import type { FC } from "react";
import { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { ExternalLink } from "@/components/ExternalLink";
import { KeyIcon } from "@/components/Icons/KeyIcon";
import { SyncIcon } from "@/components/Icons/SyncIcon";
import { Modal, type ModalProps } from "@/components/Modal";
import { Typography } from "@/components/Typography";
import { Trans } from "@/features/i18n/Trans";
import {
  ProfileUnificationWarning,
  useProductNotActivated,
} from "@/features/unify-scope-setting/ProfileUnificationWarning";
import { UnifyOrgsProvider } from "@/features/unify-scope-setting/ProfileUnificationWarning/Provider";
import { UnifyKeySelector } from "@/features/unify-scope-setting/UnifyKeySelector";
import type { InterludeTypes } from "@/interlude";
import { interlude } from "@/interlude";
import CDH_COVER_PATH from "@/statics/cdhCover.svg";
import CDH_ICON_PATH from "@/statics/cdhIcon.png";

const cssCdhIcon = css`
  --size: 132px;
  max-width: var(--size);
  max-height: var(--size);
`;

type UnifySettingFormValues = {
  unifyKeys: Array<InterludeTypes["UnificationKeys"]>;
};

const MergeState: FC = () => {
  const unifyScopeSettingQuery =
    interlude.unifyScopeSetting.useGetUnifyScopeSetting();

  const mergingStateQuery = interlude.unifyScopeSetting.useGetUnifyMergeState();

  if (!mergingStateQuery.isSuccess || !unifyScopeSettingQuery.isSuccess)
    return null;

  if (!unifyScopeSettingQuery.data.isCdhConnected) return null;

  if (mergingStateQuery.data.isFinished) {
    return (
      <Alert
        severity="success"
        variant="outlined"
        icon={<CheckCircleOutlineRoundedIcon />}
      >
        <Trans i18nKey="applications.cdh.merged.title" />
      </Alert>
    );
  }
  return (
    <Alert severity="info" icon={<SyncIcon />}>
      <Trans i18nKey="cdh.unification.merging.label" />
    </Alert>
  );
};

const InstallCdhModal: FC<
  Pick<ModalProps, "open" | "handleClose"> & { onOk: () => void }
> = ({ open, handleClose, onOk }) => {
  const { t } = useTranslation();
  const notActivatedProducts = useProductNotActivated();
  const orgs = UnifyOrgsProvider.useUnifyOrgsContext();
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={t("applications.cdh.modal.title")}
      footer={
        <Stack alignItems="flex-end">
          <Button
            onClick={onOk}
            variant="primary"
            disabled={notActivatedProducts.length !== 0}
          >
            {t("applications.cdh.modal.step1.button.nextStep")}
          </Button>
        </Stack>
      }
    >
      <>
        <div
          css={css({
            display: "flex",
            justifyContent: "center",
            marginBottom: "8px",
          })}
        >
          <img src={CDH_COVER_PATH} alt="Customer Data Hub" />
        </div>
        <Typography variant="body" css={css({ marginBottom: "16px" })}>
          {t("applications.cdh.modal.step1.content.paragraph")}
        </Typography>

        <ProfileUnificationWarning.UnifyScope orgs={orgs} />
        <ProfileUnificationWarning.RequireActivate />
      </>
    </Modal>
  );
};

const InstalCdh: FC = () => {
  const { t } = useTranslation();
  const form = useForm<UnifySettingFormValues>({
    mode: "all",
    defaultValues: {
      unifyKeys: [],
    },
  });
  const [stepOneModalOpen, toggleStepOneModalOpen] = useSwitch(false);
  const [stepTwoModalOpen, toggleStepTwoModalOpen] = useSwitch(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const integrateCdhMutation = interlude.unifyScopeSetting.useIntegrateCdh();

  const goToStepTwo = useHandler(function goToStepTwo() {
    toggleStepOneModalOpen.off();
    toggleStepTwoModalOpen.on();
  });

  const handleCloseStepTwoModal = useHandler(
    function handleCloseStepTwoModal() {
      toggleStepTwoModalOpen.off();
      setCheckboxChecked(false);
      form.reset();
    },
  );

  const onSubmit = useHandler<SubmitHandler<UnifySettingFormValues>>(
    function handleSubmit(values) {
      if (integrateCdhMutation.isLoading) return;

      integrateCdhMutation.mutate(
        {
          unifyKeys: values.unifyKeys,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(t("common.updatedSuccessfully"), {
              variant: "success",
            });
            navigate({
              to: "/applications/cdh",
            });
          },
        },
      );
    },
  );

  const meInfoQuery = interlude.auth.useGetMe(undefined);

  const submit = useHandler(function submit() {
    form.handleSubmit(onSubmit)();
  });

  return (
    <>
      <Button variant="primary" onClick={toggleStepOneModalOpen.on}>
        {t("applications.cdh.button.install")}
      </Button>
      <InstallCdhModal
        open={stepOneModalOpen}
        onOk={goToStepTwo}
        handleClose={toggleStepOneModalOpen.off}
      />
      <Modal
        open={stepTwoModalOpen}
        handleClose={handleCloseStepTwoModal}
        title={t("applications.cdh.modal.title")}
        footer={
          <Stack direction="row" justifyContent="flex-end" gap="16px">
            <Button variant="plain" onClick={handleCloseStepTwoModal}>
              {t("common.cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={submit}
              disabled={!checkboxChecked || integrateCdhMutation.isLoading}
              loading={integrateCdhMutation.isLoading}
            >
              {t("applications.cdh.modal.step2.button.install")}
            </Button>
          </Stack>
        }
      >
        <Stack gap="16px">
          <Stack gap="8px">
            <Typography variant="h2" fontWeight={700}>
              {t("applications.cdh.modal.step2.content.title")}
            </Typography>
            <Typography variant="body">
              <Trans
                i18nKey="applications.cdh.modal.step2.content.description"
                components={{
                  strong: <strong />,
                }}
                values={{
                  orgName: meInfoQuery.data?.organization.name ?? "",
                }}
              />
            </Typography>
            <Typography variant="body">
              <ExternalLink
                href={t("applications.cdh.modal.step2.unifyKey.learnMore.link")}
                trailingIcon={true}
              >
                {t("applications.cdh.modal.step2.unifyKey.learnMore")}
              </ExternalLink>
            </Typography>
          </Stack>
          <div>
            <Stack
              direction="row"
              gap="8px"
              alignItems="center"
              marginBottom="8px"
            >
              <Typography
                variant="body"
                fontWeight={500}
                color={theme.colors.staticFgTitle}
              >
                {t("applications.cdh.modal.step2.unifyKey")}
              </Typography>
              <KeyIcon
                sx={{
                  fontSize: "0.875rem",
                  color: theme.colors.staticFgPlaceholder,
                }}
              />
            </Stack>
            <Controller
              name="unifyKeys"
              control={form.control}
              rules={{ required: true }}
              render={({ field, formState }) => (
                <UnifyKeySelector
                  showCount
                  error={Boolean(formState.errors.unifyKeys)}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  helperText={
                    !formState.errors.unifyKeys ? null : (
                      <Trans i18nKey="validation.unification.unifyKeys.min" />
                    )
                  }
                />
              )}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxChecked}
                onChange={(e) => {
                  setCheckboxChecked(e.target.checked);
                }}
              />
            }
            label={
              <Typography variant="body">
                {t("applications.cdh.modal.step2.consensus")}
              </Typography>
            }
          />
        </Stack>
      </Modal>
    </>
  );
};

export const CdhCard = fc(function Cdh() {
  const { t } = useTranslation();

  const unifyScopeSettingQuery =
    interlude.unifyScopeSetting.useGetUnifyScopeSetting();

  return (
    <>
      <Card
        sx={{ display: "flex", width: "100%", gap: "8px", padding: "10px" }}
        variant="outlined"
      >
        <CardContent sx={{ padding: 0, flex: 0 }}>
          <img src={CDH_ICON_PATH} alt="Customer Data Hub" css={cssCdhIcon} />
        </CardContent>
        <CardContent
          sx={{
            display: "flex",
            flex: 1,
            gap: "inherit",
            flexDirection: "column",
            padding: "0",
            "&.MuiCardContent-root:last-child": {
              paddingBottom: "0",
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignContent="center"
            gap="inherit"
          >
            <Typography
              variant="body"
              fontWeight={500}
              css={css`
                color: ${theme.colors.staticFgTitle};
              `}
            >
              {t("applications.cdh.title")}
            </Typography>
            {unifyScopeSettingQuery.data?.isCdhConnected && (
              <Chip
                label={t("applications.cdh.connected")}
                sx={{
                  height: "20px",
                  color: theme.colors.green060,
                  backgroundColor: theme.colors.green020,
                  borderRadius: "20px",
                  ".MuiChip-label": {
                    padding: "2px 6px",
                  },
                }}
              />
            )}
          </Stack>
          <Typography
            variant="note"
            css={css`
              display: block;
              color: ${theme.colors.staticFgTitle};
            `}
          >
            {t("applications.cdh.description")}
          </Typography>
          <ExternalLink
            href={t("applications.cdh.learnMore.link")}
            trailingIcon={true}
            css={css`
              font-size: 0.875rem;
            `}
          >
            {t("applications.cdh.learnMore")}
          </ExternalLink>
          <div>
            <MergeState />
          </div>
          <Stack alignItems="flex-end">
            {unifyScopeSettingQuery.isLoading ? (
              <CircularProgress />
            ) : unifyScopeSettingQuery.data?.isCdhConnected ? (
              <Link to="/applications/cdh">
                <Button variant="plain">
                  {t("applications.cdh.button.details")}
                </Button>
              </Link>
            ) : (
              <ProfileUnificationWarning.Provider>
                <InstalCdh />
              </ProfileUnificationWarning.Provider>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
});
