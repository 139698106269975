import { memo } from "@chatbotgang/etude/react/memo";
import type { SvgIconProps } from "@mui/material/SvgIcon";
import SvgIcon from "@mui/material/SvgIcon";

export const WarningIcon = memo<SvgIconProps>(function WarningIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00033 1.33334C8.48202 1.33334 8.92627 1.59316 9.16243 2.013L15.1624 12.6797C15.3947 13.0925 15.3904 13.5976 15.1513 14.0065C14.9121 14.4154 14.474 14.6667 14.0003 14.6667H2.00033C1.52664 14.6667 1.08852 14.4154 0.84938 14.0065C0.610242 13.5976 0.605995 13.0925 0.838225 12.6797L6.83822 2.013C7.07438 1.59316 7.51863 1.33334 8.00033 1.33334ZM8.00033 2.66668L2.00033 13.3333H14.0003L8.00033 2.66668ZM7.16699 11.5001C7.16699 11.9604 7.54008 12.3335 8.00032 12.3335C8.46056 12.3335 8.83365 11.9604 8.83365 11.5001C8.83365 11.0399 8.46056 10.6668 8.00032 10.6668C7.54008 10.6668 7.16699 11.0399 7.16699 11.5001ZM8.66699 6.6668C8.66699 6.29861 8.36851 6.00013 8.00032 6.00013C7.63213 6.00013 7.33365 6.29861 7.33365 6.6668V9.33346C7.33365 9.70165 7.63213 10.0001 8.00032 10.0001C8.36851 10.0001 8.66699 9.70165 8.66699 9.33346V6.6668Z"
          fill="currentcolor"
        />
      </svg>
    </SvgIcon>
  );
});
