import type { FC } from "react";

import { Trans } from "@/features/i18n/Trans";
import { unifyKeyLabelTranslationMap } from "@/features/unify-scope-setting/unifyKeyLabelTranslationMap";
import type { InterludeTypes } from "@/interlude";

export const UnifyKeyLabel: FC<{
  unifyKey: InterludeTypes["UnificationKeys"];
}> = ({ unifyKey }) => {
  return <Trans i18nKey={unifyKeyLabelTranslationMap[unifyKey]} />;
};
