// TODO: move this to @chatbotgang/etude

import dayjs from "dayjs";
import { shallow as zustandShallow } from "zustand/shallow";

/**
 * Zustand's shallow comparison does not support comparing dates, thus requiring
 * a custom equality function.
 *
 * - [Bug in shallow when comparing dates
 *   #1064](https://github.com/pmndrs/zustand/issues/1064)
 */
const shallow: typeof zustandShallow = (a, b) => {
  /**
   * Both `a` and `b` are dates, so we can use `date-fns`'s `isEqual` to
   * compare them.
   */
  if (a instanceof Date && b instanceof Date) return dayjs(a).isSame(dayjs(b));

  /**
   * If either `a` or `b` is a date, then they are not equal.
   */
  if (a instanceof Date || b instanceof Date) return false;

  /**
   * Fallback to Zustand's default shallow equality function.
   */
  return zustandShallow(a, b);
};

export { shallow };
