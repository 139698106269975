import { css } from "@emotion/react";

/**
 * This is a helper to make a flex container fill its parent and inherit its flex properties.
 *
 * To set specific width:
 *
 * ```tsx
 * <div css={css`
 *   ${cssFlexInheritAndFill}
 *   flex: initial;
 *   width: 200px;`
 * } />
 * ```
 *
 * To set specific flex direction:
 *
 * ```tsx
 * <div css={css`
 *   ${cssFlexInheritAndFill}
 *   flex-direction: column;
 * `} />
 * ```
 */
const cssFlexInheritAndFill = css`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;
  flex: 1;
  flex-direction: inherit;
  align-items: inherit;
  justify-content: inherit;
`;

const cssInheritGap = css`
  gap: inherit;
`;

/**
 * Word wrap for defensive styling.
 */
const cssWrap = css`
  hyphens: auto;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  word-break: normal;
  word-break: auto-phrase; /* experimental */
`;

const cssOneLine = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export { cssFlexInheritAndFill, cssInheritGap, cssOneLine, cssWrap };
