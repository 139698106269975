import { memo } from "@chatbotgang/etude/react/memo";
import type { SvgIconProps } from "@mui/material/SvgIcon";
import SvgIcon from "@mui/material/SvgIcon";

export const NewWindowIcon = memo(function NewWindowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16683 4.18376H6.84516C7.21335 4.18376 7.51183 3.88528 7.51183 3.51709C7.51183 3.1489 7.21335 2.85042 6.84516 2.85042H3.11127C2.77239 2.85042 2.44738 2.98505 2.20775 3.22468C1.96812 3.46431 1.8335 3.78931 1.8335 4.1282V12.6838C1.8335 13.0226 1.96812 13.3477 2.20775 13.5873C2.44738 13.8269 2.77239 13.9615 3.11127 13.9615H11.6668C12.0057 13.9615 12.3307 13.8269 12.5704 13.5873C12.81 13.3477 12.9446 13.0226 12.9446 12.6838V8.94987C12.9446 8.58168 12.6461 8.2832 12.2779 8.2832C11.9098 8.2832 11.6113 8.58168 11.6113 8.94987V12.6282H3.16683V4.18376Z"
        fill="currentColor"
      />
      <path
        d="M14.1162 2.03965C14.0839 1.9616 14.0362 1.88844 13.973 1.82485L13.9701 1.82202C13.8496 1.70221 13.6835 1.62817 13.5001 1.62817H11.0557C10.6875 1.62817 10.389 1.92665 10.389 2.29484C10.389 2.66303 10.6875 2.96151 11.0557 2.96151H11.8907L6.91763 7.93455C6.65728 8.1949 6.65728 8.61701 6.91763 8.87736C7.17798 9.13771 7.60009 9.13771 7.86044 8.87736L12.8335 3.90432V4.73928C12.8335 5.10747 13.132 5.40595 13.5001 5.40595C13.8683 5.40595 14.1668 5.10747 14.1668 4.73928V2.2953L14.1668 2.29284C14.1666 2.20318 14.1486 2.11768 14.1162 2.03965Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
