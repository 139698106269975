import { isPrimitive } from "@chatbotgang/etude/util/isPrimitive";
import { parseJson } from "@crescendolab/parse-json";
import { INTERLUDE_API_HOST } from "@polifonia/env";
import type { AxiosResponse } from "axios";
import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import { StatusCodes } from "http-status-codes";
import queryString from "query-string";
import snakecaseKeys from "snakecase-keys";

import { useTokenStore } from "@/features/auth/useTokenStore";
import { logError } from "@/features/logger/index";

const interludeAxios = axios.create({
  baseURL: INTERLUDE_API_HOST,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: {
    serialize: (params) => {
      const snakecaseParams = isPrimitive(params)
        ? params
        : snakecaseKeys(params, { deep: true });
      return queryString.stringify(snakecaseParams);
    },
  },
  transformRequest: [
    (data) => {
      if (data === undefined) return undefined;
      if (!data) return JSON.stringify(data);
      return JSON.stringify(snakecaseKeys(data, { deep: true }));
    },
  ],
  transformResponse: [
    (data) => {
      if (!data) return data;
      const json = parseJson(data, {
        fallback: undefined,
      });
      if (isPrimitive(json)) return json;
      return camelcaseKeys(json, { deep: true });
    },
  ],
});

/**
 * Throw this error if we know the response is always 401.
 */
class UnAuthError extends Error {}

interludeAxios.interceptors.request.use((config) => {
  const token = useTokenStore.getState().value;
  if (
    // Allow overriding Authorization header. Easier to test.
    !config.headers.Authorization &&
    token
  )
    config.headers.Authorization = `Bearer ${token}`;

  if (!config.headers.Authorization && !config.url?.startsWith("/api/v1/auth"))
    throw new UnAuthError("token is required");

  return config;
});

/**
 * Log unexpected errors.
 */
interludeAxios.interceptors.response.use((response) => {
  if (response.status >= StatusCodes.INTERNAL_SERVER_ERROR) logError(response);
  return response;
});

function expireTokenIfUnauthorized(response: AxiosResponse) {
  if (response.status !== StatusCodes.UNAUTHORIZED) return;
  if (
    !response.config.headers["Authorization"] ||
    typeof response.config.headers["Authorization"] !== "string"
  )
    return;
  const tokenFromRequestHeader = response.config.headers[
    "Authorization"
  ].substring("Bearer ".length);
  if (!tokenFromRequestHeader) return;

  const tokenFromStore = useTokenStore.getState().value;
  if (!tokenFromStore || tokenFromStore !== tokenFromRequestHeader) return;

  useTokenStore.getState().setValue("");
}

/**
 * Expire token when unauthorized.
 */
interludeAxios.interceptors.response.use(
  (response) => {
    (() => {
      expireTokenIfUnauthorized(response);
    })();
    return response;
  },
  (error: unknown) => {
    (() => {
      if (!axios.isAxiosError(error)) return;
      if (!error.response) return;
      expireTokenIfUnauthorized(error.response);
      return;
    })();
    return Promise.reject(error);
  },
);

export { interludeAxios, UnAuthError };
