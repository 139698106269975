// The color palettes are defined in Motif Colors. They will be re-organized to a standalone repository in the future.
// See the specification: https://www.notion.so/cresclab/Color-3c3bdbd1d18d4f70ba85f7fde022a61e
enum NeutralPalette {
  neutral000 = "#FFFFFF",
  neutral010 = "#F8F9FB",
  neutral020 = "#E7EBEF",
  neutral030 = "#D7DEE4",
  neutral040 = "#C7D0D9",
  neutral050 = "#BBC6D1",
  neutral060 = "#AFBCC9",
  neutral070 = "#8C9CAB",
  neutral080 = "#5C7388",
  neutral090 = "#425A70",
  neutral100 = "#223B53",
}

enum BluePalette {
  blue010 = "#F3F9FF",
  blue020 = "#E7F2FF",
  blue030 = "#B5D7FF",
  blue040 = "#7DAFFF",
  blue050 = "#5998FF",
  blue060 = "#4586F0",
  blue070 = "#1F56E5",
}

enum RedPalette {
  red010 = "#FFF3EF",
  red020 = "#FFEBE4",
  red030 = "#FFBAAB",
  red040 = "#FF8569",
  red050 = "#E9573B",
  red060 = "#D93721",
  red070 = "#CF2A18",
}

enum YellowPalette {
  yellow010 = "#FFFAE4",
  yellow020 = "#FFF6D0",
  yellow030 = "#FFE976",
  yellow040 = "#FFD235",
  yellow050 = "#FAB918",
  yellow060 = "#F29B00",
  yellow070 = "#EE8900",
}

enum GreenPalette {
  green010 = "#F2FDF1",
  green020 = "#E7FBE7",
  green030 = "#99ED9E",
  green040 = "#6AD170",
  green050 = "#43B849",
  green060 = "#179C34",
  green070 = "#118A27",
}

enum PurplePalette {
  purple010 = "#F8F2FF",
  purple020 = "#F2E8FF",
  purple030 = "#D4B9F9",
  purple040 = "#B98EF5",
  purple050 = "#A257FF",
  purple060 = "#8020F8",
  purple070 = "#6C1DED",
}

const tokens = {
  staticFgTitle: NeutralPalette.neutral100,
  staticFgBody: NeutralPalette.neutral090,
  staticFgNote: NeutralPalette.neutral070,
  staticFgPlaceholder: NeutralPalette.neutral060,
  staticFgLine: NeutralPalette.neutral030,
  staticFgPrimary: BluePalette.blue060,
  staticFgPlain: NeutralPalette.neutral090,
  staticFgUnread: RedPalette.red050,
  staticFgPositive: GreenPalette.green060,
  staticFgAttentive: YellowPalette.yellow060,
  staticFgNegative: RedPalette.red060,
  staticFgDisable: NeutralPalette.neutral060,
  staticBgPage: NeutralPalette.neutral000,
  staticBgContainer: NeutralPalette.neutral010,
  staticBgPrimary: BluePalette.blue020,
  staticBgPlain: NeutralPalette.neutral020,
  staticBgPositive: GreenPalette.green020,
  staticBgAttentive: YellowPalette.yellow020,
  staticBgNegative: RedPalette.red020,
  colorNeutralWhite: NeutralPalette.neutral000,
  colorNeutral10: NeutralPalette.neutral010,
  colorNeutral20: NeutralPalette.neutral020,
  colorNeutral30: NeutralPalette.neutral030,
  colorNeutral40: NeutralPalette.neutral040,
  colorNeutral50: NeutralPalette.neutral050,
  colorNeutral60: NeutralPalette.neutral060,
  colorNeutral70: NeutralPalette.neutral070,
  colorNeutral80: NeutralPalette.neutral080,
  colorNeutral90: NeutralPalette.neutral090,
  colorNeutral100: NeutralPalette.neutral100,
  staticBgDisable: NeutralPalette.neutral010,
  staticBgSuperior: BluePalette.blue060,
  colorPurple10: PurplePalette.purple010,
  colorPurple30: PurplePalette.purple030,
  colorPurple20: PurplePalette.purple020,
  colorPurple60: PurplePalette.purple060,
  colorPurple40: PurplePalette.purple040,
  colorPurple70: PurplePalette.purple070,
  colorPurple50: PurplePalette.purple050,
  colorYellow30: YellowPalette.yellow030,
  colorYellow10: YellowPalette.yellow010,
  colorYellow20: YellowPalette.yellow020,
  colorYellow50: YellowPalette.yellow050,
  colorYellow60: YellowPalette.yellow060,
  colorYellow40: YellowPalette.yellow040,
  colorYellow70: YellowPalette.yellow070,
  colorRed30: RedPalette.red030,
  colorRed20: RedPalette.red020,
  colorRed10: RedPalette.red010,
  colorRed50: RedPalette.red050,
  colorRed40: RedPalette.red040,
  colorRed60: RedPalette.red060,
  colorRed70: RedPalette.red070,
  colorGreen30: GreenPalette.green030,
  colorGreen10: GreenPalette.green010,
  colorGreen20: GreenPalette.green020,
  colorGreen50: GreenPalette.green050,
  colorGreen40: GreenPalette.green040,
  colorGreen60: GreenPalette.green060,
  colorGreen70: GreenPalette.green070,
  colorBlue10: BluePalette.blue010,
  colorBlue20: BluePalette.blue020,
  colorBlue30: BluePalette.blue030,
  colorBlue40: BluePalette.blue040,
  colorBlue50: BluePalette.blue050,
  colorBlue60: BluePalette.blue060,
  colorBlue70: BluePalette.blue070,
  buttonPrimaryPrimaryDefault: BluePalette.blue060,
  dataIndividual1st: BluePalette.blue060,
  dataLevelLevel1: NeutralPalette.neutral060,
  dataGradual100: BluePalette.blue060,
  dataChannelLine: GreenPalette.green060,
  dataContactActive: BluePalette.blue060,
  tabsFgInactive: NeutralPalette.neutral070,
  buttonNamePrimaryHovered: BluePalette.blue050,
  buttonNamePrimaryFocused: BluePalette.blue030,
  buttonNamePrimaryActive: BluePalette.blue070,
  buttonNamePrimaryDisabled: NeutralPalette.neutral060,
  tabsFgActive: BluePalette.blue060,
  buttonPlainDefault: NeutralPalette.neutral090,
  buttonPlainActive: BluePalette.blue070,
  buttonPlainHover: BluePalette.blue050,
  buttonPlainFocus: BluePalette.blue030,
  buttonPlainDisabled: NeutralPalette.neutral060,
  dataIndividual2nd: BluePalette.blue040,
  dataIndividual3rd: BluePalette.blue030,
  dataIndividual4th: GreenPalette.green060,
  dataIndividual5th: GreenPalette.green040,
  dataIndividual6th: GreenPalette.green030,
  dataIndividual7th: YellowPalette.yellow060,
  dataIndividual8th: YellowPalette.yellow040,
  dataIndividual9th: YellowPalette.yellow030,
  dataIndividual10th: PurplePalette.purple060,
  dataIndividual11th: PurplePalette.purple040,
  dataIndividual12th: PurplePalette.purple030,
  dataLevelLevel2: YellowPalette.yellow060,
  dataLevelLevel4: BluePalette.blue060,
  dataLevelLevel3: GreenPalette.green060,
  dataLevelLevel5: PurplePalette.purple060,
  dataGradual80: BluePalette.blue050,
  dataGradual60: BluePalette.blue040,
  dataGradual40: BluePalette.blue030,
  dataGradual0: NeutralPalette.neutral000,
  dataGradual20: BluePalette.blue020,
  dataChannelFacebook: BluePalette.blue060,
  dataChannelSms: RedPalette.red060,
  dataChannelInstagram: PurplePalette.purple060,
  dataChannelWhatsapp: GreenPalette.green040,
  dataChannelWebchat: BluePalette.blue040,
  dataContactAdded: GreenPalette.green060,
  dataContactBlocked: RedPalette.red060,
  dataContactFollowing: BluePalette.blue040,
  checkboxBorderPress: BluePalette.blue070,
  checkboxFgHover: NeutralPalette.neutral000,
  checkboxFgDisable: NeutralPalette.neutral060,
  checkboxFgError: NeutralPalette.neutral000,
  checkboxBgDisable: NeutralPalette.neutral010,
  checkboxBorderDisable: NeutralPalette.neutral030,
  checkboxBorderFocus: BluePalette.blue030,
  radioButtonFgEnable: BluePalette.blue060,
  checkboxBorderHover: BluePalette.blue050,
  checkboxBorderEnable: BluePalette.blue060,
  checkboxBgHover: BluePalette.blue050,
  checkboxBgEnable: BluePalette.blue060,
  checkboxBgError: RedPalette.red060,
  checkboxBorderError: RedPalette.red060,
  staticFgSuperior: NeutralPalette.neutral000,
  badgeBgActive: BluePalette.blue060,
  badgeFgActive: NeutralPalette.neutral000,
  badgeFgInactive: NeutralPalette.neutral090,
  tabsFgDisable: NeutralPalette.neutral060,
  tabsFgFocus: BluePalette.blue030,
  tabsFgHover: BluePalette.blue050,
  badgeBgInactive: NeutralPalette.neutral020,
  badgeFgHover: NeutralPalette.neutral000,
  badgeBgHover: BluePalette.blue050,
  checkboxFgEnable: NeutralPalette.neutral000,
  radioButtonFgDisable: NeutralPalette.neutral060,
  radioButtonFgError: RedPalette.red060,
  radioButtonFgHover: BluePalette.blue050,
  radioButtonBgDisable: NeutralPalette.neutral010,
  radioButtonBorderEnableUnselected: BluePalette.blue060,
  radioButtonBorderDisable: NeutralPalette.neutral030,
  radioButtonBorderEnableSelected: NeutralPalette.neutral030,
  radioButtonBorderError: RedPalette.red060,
  radioButtonBorderFocus: BluePalette.blue030,
  radioButtonBorderHover: BluePalette.blue050,
  checkboxFgPress: NeutralPalette.neutral000,
  checkboxBgPress: BluePalette.blue070,
  radioButtonFgPress: BluePalette.blue070,
  radioButtonBorderPress: BluePalette.blue070,
};

export const colors = {
  ...NeutralPalette,
  ...BluePalette,
  ...GreenPalette,
  ...RedPalette,
  ...YellowPalette,
  ...PurplePalette,
  primary: BluePalette.blue060,
  neutral: "#D2DCE4",
  success: "#52c41a",
  error: "#ff4d4f",
  warning: "#faad14",
  info: "#1677ff",
  black: "#000",
  white: NeutralPalette.neutral000,
  ...tokens,
};

/**
 * Convert a string to a color which is in the provided color array.
 * The same string will always return the same color.
 */
export function stringToColor(str: string, colors: Array<string>) {
  function hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }

  // Convert the string to a unique hash value
  const hash = hashCode(str);

  // Use the hash to calculate an index
  const colorIndex = hash % colors.length;

  return colors[colorIndex];
}
