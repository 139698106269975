import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getGlobalErrorTranslationKey } from "@/features/error/handleError";
import { mutationErrorEventEmitter } from "@/features/error/mutationErrorEventEmitter";

export const GlobalErrorHint = fc(function GlobalErrorHint() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const errorHandler = useHandler(function errorHandler(err: unknown) {
    const translationKey = getGlobalErrorTranslationKey(err);
    if (translationKey) {
      enqueueSnackbar(t(translationKey), {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  });

  useEffect(
    function bindMutationErrorEvent() {
      mutationErrorEventEmitter.on("error", errorHandler);
      return function cleanup() {
        mutationErrorEventEmitter.off("error", errorHandler);
      };
    },
    [errorHandler],
  );

  return null;
});
