import { availableLocales } from "@/config/availableLocales";
import { defineConfig } from "@/features/featureFlag/defineConfig";

export const featureFlagConfigs = defineConfig({
  alwaysHideDevModeHint: {
    type: "toggle",
    label: "Always hide DevMode hint",
  },
  showApplicationPage: {
    type: "toggle",
    label: "Show Application page entry on Sidemenu",
    autoEnableAt: new Date("2024-05-07T01:00:00+08:00"),
  },
  quickI18n: {
    label: "Quick switch language",
    type: "singleSelect",
    options: availableLocales.map((locale) => ({
      value: locale.code,
      label: locale.displayName,
    })),
  },
  "tanstack-router-devtool": {
    label: "Enable tanstack router devtool",
    type: "singleSelect",
    options: [
      {
        value: "bottom-right",
        label: "Bottom right",
      },
      {
        value: "top-left",
        label: "Top left",
      },
      {
        value: "top-right",
        label: "Top right",
      },
      {
        value: "bottom-left",
        label: "Bottom left",
      },
    ],
  },
  "react-query-devtool": {
    label: "Enable react-query devtool",
    type: "singleSelect",
    options: [
      {
        value: "bottom",
        label: "Bottom",
      },
      {
        value: "top",
        label: "Top",
      },
      {
        value: "right",
        label: "Right",
      },
      {
        value: "left",
        label: "Left",
      },
    ],
  },
});
