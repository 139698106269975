import { makeApi } from "@zodios/core";
import { z } from "zod";

const api = makeApi([
  {
    alias: "getBalance",
    method: "get",
    path: "/api/v1/billing/balance",
    response: z.object({
      actualBalance: z.number(),
      reservedBalance: z.number(),
      availableBalance: z.number(),
    }),
  },
  {
    alias: "getBalanceThreshold",
    method: "get",
    path: "/api/v1/billing/balance/threshold",
    response: z.object({
      threshold: z.number(),
    }),
  },
  {
    alias: "setBalanceThreshold",
    method: "put",
    path: "/api/v1/billing/balance/threshold",
    parameters: [
      {
        type: "Body",
        name: "threshold",
        schema: z.object({
          threshold: z.number().positive(),
        }),
      },
    ],
    response: z.object({
      threshold: z.number().positive(),
    }),
  },
]);

export { api };
