import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useEffect, useMemo, useState } from "react";

import type { ButtonProps } from "@/components/Button";
import { Button } from "@/components/Button";

type ReloadButtonProps = ButtonProps;

const READY_DELAY = 1000;

/**
 * Can only click once.
 * setTimeout to prevent continuous clicks.
 */
const ReloadButton = memo(function ReloadButton({
  disabled,
  onClick,
  ...props
}: ReloadButtonProps) {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true);
    }, READY_DELAY);
    return function cleanUp() {
      clearTimeout(timeout);
    };
  }, []);
  const isDisabled = useMemo(() => {
    return disabled || clicked || !ready;
  }, [clicked, disabled, ready]);
  const clickHandler = useHandler<ButtonProps["onClick"]>((e) => {
    setClicked(true);
    onClick?.(e);
  });
  return <Button {...props} disabled={isDisabled} onClick={clickHandler} />;
});

export { ReloadButton };
