import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { ElementRef } from "react";
// eslint-disable-next-line no-restricted-imports -- Extended from react-error-boundary
import type { ErrorBoundaryProps } from "react-error-boundary";
// eslint-disable-next-line no-restricted-imports -- Extended from react-error-boundary
import { ErrorBoundary } from "react-error-boundary";

import { logError } from "@/features/logger/index";

type ErrorBoundaryWithLogProps = ErrorBoundaryProps;

const ErrorBoundaryWithLog = memo(
  forwardRef<ElementRef<typeof ErrorBoundary>, ErrorBoundaryProps>(
    function ErrorBoundaryWithLog(props, ref) {
      const onError = useHandler<ErrorBoundaryProps["onError"]>(
        function onError(...args) {
          const error = args[0];
          logError(error);
          return props.onError?.(...args);
        },
      );
      return <ErrorBoundary {...props} onError={onError} ref={ref} />;
    },
  ),
);

export { ErrorBoundaryWithLog as ErrorBoundary };
export type { ErrorBoundaryWithLogProps as ErrorBoundaryProps };
