const times = 4;
const touches = 3;
const timeoutMs = 300;

function registerTaps(fn: () => void): void {
  let count = 0;
  let timeoutInstance: null | ReturnType<typeof setTimeout> = null;
  function clearTimeoutInstance() {
    if (!timeoutInstance) return;
    clearTimeout(timeoutInstance);
    timeoutInstance = null;
  }
  function resetTimeoutInstance() {
    clearTimeoutInstance();
    timeoutInstance = setTimeout(clear, timeoutMs);
  }
  function clear() {
    count = 0;
    clearTimeoutInstance();
    document.addEventListener("touchstart", onTouchStart);
    document.removeEventListener("touchend", onTouchEnd);
  }
  function waitNext() {
    document.addEventListener("touchstart", onTouchStart);
    document.removeEventListener("touchend", onTouchEnd);
    resetTimeoutInstance();
  }
  const onTouchEnd: Parameters<
    typeof document.addEventListener<"touchend">
  >[1] = function onTouchEnd(e) {
    // Wait for all fingers to be lifted.
    if (e.touches.length > 0) return;
    count += 1;
    if (count >= times) {
      count = 0;
      clear();
      fn();
    } else {
      waitNext();
    }
  };
  const onTouchStart: Parameters<
    typeof document.addEventListener<"touchstart">
  >[1] = function onTouchStart(e) {
    if (e.touches.length < touches) return;
    document.addEventListener("touchend", onTouchEnd);
    document.removeEventListener("touchstart", onTouchStart);
  };

  clear();
}

export { registerTaps };
