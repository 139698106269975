export const availableLocales = [
  {
    code: "zh-hant",
    defaultFallback: false,
    displayName: "繁體中文",
  },
  {
    code: "en",
    defaultFallback: true,
    displayName: "English",
  },
  {
    code: "th",
    defaultFallback: true,
    displayName: "ภาษาไทย",
  },
  {
    code: "ja",
    defaultFallback: true,
    displayName: "日本語",
  },
] as const;
