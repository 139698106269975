import { fakeT } from "@polifonia/utils/react/fakeT";
import { createRootRoute, createRoute } from "@tanstack/react-router";

import type { InterludeTypes } from "@/interlude";
import { ErrorFallbackPage } from "@/layout/ErrorBoundary/ErrorFallbackPage/index";
import { tokenSearchSchema } from "@/layout/types";
import { ApplicationPage } from "@/pages/applications";
import { CdhPage } from "@/pages/applications/pages/cdh";
import { BillingPage } from "@/pages/billing";
import { RootComponent } from "@/pages/RootComponent";

const t = fakeT;

export const menuItems = {
  applications: {
    path: "/applications",
    labelTranslationKey: t("sideMenu.applications"),
  } as const,
  billing: {
    path: "/billing",
    labelTranslationKey: t("sideMenu.billing"),
  } as const,
} satisfies Record<
  InterludeTypes["meMenu"][number],
  {
    path: string;
    labelTranslationKey: string;
  }
>;

const rootRoute = createRootRoute({
  validateSearch: tokenSearchSchema,
  component: () => <RootComponent />,
  notFoundComponent: () => <RootComponent />,
  errorComponent: (props) => (
    <ErrorFallbackPage
      resetErrorBoundary={() => {}}
      error={props.error}
      reloadWindow
    />
  ),
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => <ApplicationPage />,
});

const applicationRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/applications",
  component: () => <ApplicationPage />,
});

const cdhRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/applications/cdh",
  component: () => <CdhPage />,
});

const billingRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/billing",
  component: () => <BillingPage />,
});

export const routeTree = rootRoute.addChildren([
  indexRoute,
  applicationRoute,
  billingRoute,
  cdhRoute,
]);
