import { SENTRY_DSN, SENTRY_RELEASE, SERVER_REGION_ENV } from "@polifonia/env";
import * as Sentry from "@sentry/react";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SERVER_REGION_ENV,
    release: SENTRY_RELEASE,
    ignoreErrors: [
      /**
       * This is a workaround for [the issue](https://sentry.io/organizations/crescendo-lab/issues/3330015257/?project=6298602)
       * See: https://stackoverflow.com/a/50387233, https://stackoverflow.com/a/50387233
       */
      "ResizeObserver loop limit exceeded",
    ],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    /**
     * To disable truncation, set the `maxValueLength` to `Infinity`. The
     * default value is 250, which is often insufficient for ZodError.
     */
    maxValueLength: Infinity,
  });
}
