export const APP_LOADED_DATE = new Date();

const LOCAL_STORAGE_PREFIX = "admin-center" as const;

function createLocalStorageKey<T extends string>(
  key: T,
): `${typeof LOCAL_STORAGE_PREFIX}-${T}` {
  return `${LOCAL_STORAGE_PREFIX}-${key}`;
}

/* Authorization bearer token key in localStorage */
export const LOCAL_STORAGE_AUTH_TOKEN_KEY = createLocalStorageKey("token");
export const LOCAL_STORAGE_FEATURE_FLAG = createLocalStorageKey("featureFlag");
export const LOCAL_STORAGE_I18NEXT = createLocalStorageKey("i18nextLng");

export const INPUT_NUMBER_PREVENT_KEY = ["=", "-", ".", "+", "e"];

export const GLOBAL_SEARCH_PARAM_FEATURE_FLAG = "featureFlag";
export const GLOBAL_SEARCH_PARAM_I18NEXT = "lang";

// Shared placeholders
export const EMPTY_STRING_PLACEHOLDER = "－";
export const NBSP = "\u00A0";
