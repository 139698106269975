import { GlobalErrorSchema } from "@polifonia/interlude/models";
import { fakeT } from "@polifonia/utils/react/fakeT";
import { AxiosError } from "axios";

import { logError } from "@/features/logger";
import type { InterludeTypes } from "@/interlude";
import { UnAuthError } from "@/interlude/interludeAxios";
const networkError = {
  message: "",
  code: Symbol("NetworkError"),
};

const noTokenError = {
  message: "",
  code: Symbol("NoTokenError"),
};

type GlobalApiErrorResponseData =
  | InterludeTypes["GlobalError"]
  | typeof networkError
  | typeof noTokenError;

type GlobalApiErrorCode = GlobalApiErrorResponseData["code"];

const t = fakeT;
const globalApiErrorCodeTranslationKeyMap = {
  [networkError.code]: t("common.apiError.networkError"),
  [noTokenError.code]: t("errorPage.tryFromTheOriginalApp"),
  UNAUTHORIZED: t("errorPage.tryFromTheOriginalApp"),
} satisfies Record<GlobalApiErrorCode, string>;

export const parseGlobalApiError = (input: unknown) => {
  if (input instanceof UnAuthError) {
    return noTokenError;
  }
  if (input instanceof AxiosError) {
    if (input.code === "ERR_NETWORK") return networkError;

    const parseErrorResult = GlobalErrorSchema.safeParse(input.response?.data);
    if (parseErrorResult.success) return parseErrorResult.data;
  }
  return null;
};

export const getGlobalErrorTranslationKey = (error: unknown) => {
  const parsedError = parseGlobalApiError(error);
  if (parsedError) {
    return globalApiErrorCodeTranslationKeyMap[parsedError.code];
  }
  logError(error);
};
