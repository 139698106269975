import { z } from "zod";

export const GlobalErrorSchema = z.object({
  message: z.string(),
  code: z.enum(["UNAUTHORIZED"]),
});

export const NonGlobalErrorSchema = z.object({
  message: z.string(),
  code: z.enum([
    "BAD_REQUEST",
    "ORGANIZATION_NOT_FOUND",
    "SUBSCRIPTION_NOT_FOUND",
  ]),
});

export const meMenuSchema = z.enum(["applications", "billing"]).array();

export type MeMenu = z.output<typeof meMenuSchema>;

export const UnificationKeysSchema = z.enum([
  "display_email",
  "custom_id",
  "connect_id",
  "display_mobile",
  "line_id",
]);

export const OrgSchema = z.object({
  id: z.number().int(),
  source: z.enum(["maac", "caac"]),
  name: z.string(),
  uuid: z.string(),
});

export const UnifyScopeSettingSchema = z.object({
  unifyScopeId: z.number().int(),
  isCdhConnected: z.boolean(),
  orgs: OrgSchema.array(),
  unifyKeys: z
    .object({
      attribute: UnificationKeysSchema,
      isDistinct: z.boolean(),
    })
    .array(),
});
