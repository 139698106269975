import { memo } from "@chatbotgang/etude/react/memo";
// eslint-disable-next-line no-restricted-imports -- For inheriting props
import type {
  ErrorBoundaryPropsWithRender,
  FallbackProps,
} from "react-error-boundary";

import { ErrorBoundary } from "@/layout/ErrorBoundary/ErrorBoundary";
import type { ErrorFallbackPageProps } from "@/layout/ErrorBoundary/ErrorFallbackPage";
import { ErrorFallbackPage } from "@/layout/ErrorBoundary/ErrorFallbackPage";

// This will expose the props to the component instead of using the `ErrorFallbackPageProps` prop.
type HoistedPropKey = "reloadWindow" | "css";

type PageErrorBoundaryProps = Omit<
  ErrorBoundaryPropsWithRender,
  "fallbackRender"
> &
  Pick<ErrorFallbackPageProps, HoistedPropKey> & {
    ErrorFallbackPageProps?: Omit<
      ErrorFallbackPageProps,
      // These props will be passed in `fallbackRender` function.
      | keyof FallbackProps
      // Hoisted props will be passed directly to the component.
      | HoistedPropKey
    >;
  };

export const PageErrorBoundary = memo(function PageErrorBoundary({
  children,
  ErrorFallbackPageProps,
  ...props
}: PageErrorBoundaryProps) {
  return (
    <ErrorBoundary
      fallbackRender={(fallbackProps) => (
        <ErrorFallbackPage {...ErrorFallbackPageProps} {...fallbackProps} />
      )}
      {...props}
    >
      {children}
    </ErrorBoundary>
  );
});
