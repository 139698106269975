import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useNow } from "@polifonia/utils/react/useNow";
import type { ComponentProps } from "react";

import { APP_LOADED_DATE } from "@/appConstant";
import { Button } from "@/components/Button";
import { CountDown } from "@/components/CountDown";
import type { CommonFeatureFlagsTypes } from "@/features/featureFlag";
import type { BaseToggleFeatureFlagConfig } from "@/features/featureFlag/baseTypes";
import { commonConfig } from "@/features/featureFlag/commonConfig";
import { commonFeatureFlagsApi } from "@/features/featureFlag/index";

const {
  configs,
  isToggleFeatureFlagKey,
  isSingleSelectFeatureFlagKey,
  useFeatureFlagLocalStorageStore,
  useFeatureFlagStore,
} = commonFeatureFlagsApi;

function useShouldAutoEnable(feature: BaseToggleFeatureFlagConfig) {
  const now = useNow();
  return feature.autoEnableAt !== undefined && feature.autoEnableAt < now;
}

function getAutoEnabled(feature: BaseToggleFeatureFlagConfig) {
  return (
    feature.autoEnableAt !== undefined && feature.autoEnableAt < APP_LOADED_DATE
  );
}

const AutoEnableMessage = memo(function AutoEnableMessage({
  featureFlagKey,
  autoEnableAt,
}: {
  featureFlagKey: CommonFeatureFlagsTypes["ToggleKey"];
  autoEnableAt: BaseToggleFeatureFlagConfig["autoEnableAt"];
}) {
  const autoEnabled = getAutoEnabled(configs[featureFlagKey]);
  const shouldAutoEnabled = useShouldAutoEnable(configs[featureFlagKey]);
  return (
    <>
      {autoEnableAt === undefined ? null : (
        <div>
          {shouldAutoEnabled ? (
            autoEnabled ? (
              "Enabled automatically"
            ) : (
              "Please refresh to enable automatically"
            )
          ) : (
            <>
              Will be enabled in: <CountDown targetDate={autoEnableAt} />
            </>
          )}
        </div>
      )}
    </>
  );
});

function getLabel(featureFlagKey: CommonFeatureFlagsTypes["Key"]) {
  return commonConfig[featureFlagKey].label ?? featureFlagKey;
}

const FeatureItemToggle = memo(function FeatureItemToggle<
  Key extends CommonFeatureFlagsTypes["ToggleKey"],
>({ featureFlagKey }: { featureFlagKey: Key }) {
  const label = getLabel(featureFlagKey);
  const feature = configs[featureFlagKey];
  const autoEnabled = getAutoEnabled(configs[featureFlagKey]);

  const checked: boolean = useFeatureFlagStore(
    (state) => state.value[featureFlagKey],
  );

  const onChange = useHandler(() =>
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: !current[featureFlagKey],
    })),
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked ?? false}
          onChange={onChange}
          disabled={autoEnabled}
        />
      }
      label={
        <>
          {label}
          {feature.autoEnableAt === undefined ? null : (
            <AutoEnableMessage
              featureFlagKey={featureFlagKey}
              autoEnableAt={feature.autoEnableAt}
            />
          )}
        </>
      }
    />
  );
});

const FeatureItemSingleSelect = memo(function FeatureItemSingleSelect<
  Key extends CommonFeatureFlagsTypes["SingleSelectKey"],
>({ featureFlagKey }: { featureFlagKey: Key }) {
  const label = getLabel(featureFlagKey);
  const feature = configs[featureFlagKey];
  const value = useFeatureFlagStore((state) => state.value[featureFlagKey]);
  const enabled = value !== null;

  const changeHandler = useHandler<
    ComponentProps<typeof RadioGroup>["onChange"]
  >((e) => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: e.target
        .value as CommonFeatureFlagsTypes["Values"][Key],
    }));
  });

  const enable = useHandler(() => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: feature.options[0]?.value,
    }));
  });
  const disable = useHandler(() => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: null,
    }));
  });

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox checked={enabled} onChange={enabled ? disable : enable} />
        }
        label={label}
      />
      <FormControl style={{ paddingLeft: "2em" }}>
        <RadioGroup onChange={changeHandler} value={value} row>
          {feature.options.map(
            ({ value: targetValue, label = targetValue }) => (
              <FormControlLabel
                key={targetValue}
                control={<Radio value={targetValue} />}
                label={label}
              />
            ),
          )}
        </RadioGroup>
      </FormControl>
    </>
  );
});

const FeatureItem = memo<{
  featureFlagKey: CommonFeatureFlagsTypes["Key"];
}>(function FeatureItem({ featureFlagKey }) {
  if (isToggleFeatureFlagKey(featureFlagKey))
    return <FeatureItemToggle featureFlagKey={featureFlagKey} />;
  if (isSingleSelectFeatureFlagKey(featureFlagKey))
    return <FeatureItemSingleSelect featureFlagKey={featureFlagKey} />;
  featureFlagKey satisfies never;
  throw new Error(
    inspectMessage`Unhandled feature flag type: ${featureFlagKey}`,
  );
});

const cssFeatureBox = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const Feature = memo(function Feature() {
  const { clear } = useFeatureFlagLocalStorageStore(({ value, clear }) => ({
    value,
    clear,
  }));

  return (
    <div css={cssFeatureBox}>
      <FormGroup>
        {Object.entries(configs).map(([key]) => {
          const featureFlagKey = key as CommonFeatureFlagsTypes["Key"];
          return <FeatureItem featureFlagKey={featureFlagKey} key={key} />;
        })}
      </FormGroup>
      <Button onClick={clear} variant="primary">
        Clear All
      </Button>
    </div>
  );
});
