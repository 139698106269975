import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import {
  createContext as createContextRaw,
  useContext as useContextRaw,
} from "react";

/**
 * A shorthand to create a context and its provider.
 */
function createContext<T>(
  options: {
    defaultValue?: T;
    /**
     * TODO: assignDisplayName
     */
    name?: string;
  } = {},
) {
  const Context = createContextRaw<T | undefined>(options.defaultValue);
  const Provider = Context.Provider;
  const providerName = options.name || "AnonymousContextProvider";
  assignDisplayName(Provider, providerName);
  function useContext() {
    const ret = useContextRaw(Context);
    if (ret === undefined) {
      throw new Error(
        inspectMessage`useContext must be inside ${providerName} with a value`,
      );
    }
    return ret;
  }
  const context = {
    Provider,
    useContext,
  };
  return context;
}

export { createContext };
