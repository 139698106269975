import { memo } from "@chatbotgang/etude/react/memo";
import { lazy, Suspense } from "react";

import { GlobalErrorHint } from "@/features/error/GlobalErrorHint";
import { useFeatureFlag, withFeatureFlagWrapper } from "@/features/featureFlag";
import { ApplyI18nFromFeatureFlag } from "@/features/i18n/i18n";
import { DevMode } from "@/layout/DevMode";
import { MainContent } from "@/layout/MainContent";

const TanStackRouterDevtools =
  import.meta.env.PROD === true
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

const TanStackRouterDevtoolsByFeatureFlag = withFeatureFlagWrapper(
  "tanstack-router-devtool",
  memo(function TanStackRouterDevtoolsByFeatureFlag() {
    const position = useFeatureFlag("tanstack-router-devtool");

    if (!position) return null;

    return (
      <Suspense>
        <TanStackRouterDevtools position={position} />
      </Suspense>
    );
  }),
);

export const RootComponent = () => (
  <Suspense>
    <MainContent />
    <GlobalErrorHint />
    <DevMode />
    <ApplyI18nFromFeatureFlag />
    <TanStackRouterDevtoolsByFeatureFlag />
  </Suspense>
);
