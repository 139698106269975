import { fc } from "@chatbotgang/etude/react/fc";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { theme } from "@polifonia/theme";
import type { CustomContentProps } from "notistack";
import { SnackbarContent, SnackbarProvider, useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";

import { Typography } from "@/components/Typography";

export interface ToastProps extends CustomContentProps {}

/**
 * Custom Toast component for notistack. This is a static component and should not be used directly.
 * You should use ToastProvider along with enqueueSnackbar. See {@link ToastProvider} for more details.
 */
export const Toast = forwardRef<HTMLDivElement, ToastProps>(
  ({ id, message, variant, style, ...other }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const icon = useMemo(() => {
      switch (variant) {
        case "success":
          return (
            <CheckCircleRoundedIcon
              fontSize="small"
              sx={{ color: theme.colors.success, fontSize: "18px" }}
            />
          );
        case "error":
          return (
            <CancelRoundedIcon
              fontSize="small"
              sx={{ color: theme.colors.error, fontSize: "18px" }}
            />
          );
        case "warning":
          return (
            <ErrorRoundedIcon
              fontSize="small"
              sx={{ color: theme.colors.warning, fontSize: "18px" }}
            />
          );
        case "info":
          return (
            <InfoRoundedIcon
              fontSize="small"
              sx={{ color: theme.colors.info, fontSize: "18px" }}
            />
          );
        case "default":
          return null;
        default:
          variant satisfies never;
          return null;
      }
    }, [variant]);

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent
        ref={ref}
        role="alert"
        {...other}
        style={{
          margin: "0 auto",
          padding: "4px 4px 4px 14px",
          minWidth: "fit-content",
          width: "fit-content",
          color: theme.colors.black,
          boxShadow: theme.shadows[0],
          backgroundColor: theme.colors.white,
          borderRadius: "8px",
          ...style,
        }}
      >
        <Stack direction="row" alignItems="center" gap="8px">
          {icon}
          <Typography
            variant="body"
            style={{
              margin: 0,
            }}
          >
            {message}
          </Typography>
          <IconButton size="small" onClick={handleDismiss}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Stack>
      </SnackbarContent>
    );
  },
);

/**
 * Inject global Toast props and set up the behavior. It's built on notistack.
 *
 * @example
 * ```tsx
 *  <ToastProvider>
 *    <App />
 *  </ToastProvider>
 *
 * // In App component
 * import { useSnackbar } from "notistack";
 *
 * const { enqueueSnackbar } = useSnackbar();
 * enqueueSnackbar("Your toast message", { variant: "success" })
 * ```
 */
export const ToastProvider = fc<{ children: React.ReactNode }>(
  function ToastProvider({ children }) {
    return (
      <SnackbarProvider
        autoHideDuration={1500}
        maxSnack={3}
        Components={{
          default: Toast,
          success: Toast,
          error: Toast,
          warning: Toast,
          info: Toast,
        }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "down" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {children}
      </SnackbarProvider>
    );
  },
);
