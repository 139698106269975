import { createSvgIcon } from "@mui/material/utils";

/**
 * Only use this for modal warning
 */
export const ExclamationIcon = createSvgIcon(
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M57.5 64.6875H52.5C52.1562 64.6875 51.875 64.4062 51.875 64.0625V27.8125C51.875 27.4688 52.1562 27.1875 52.5 27.1875H57.5C57.8438 27.1875 58.125 27.4688 58.125 27.8125V64.0625C58.125 64.4062 57.8438 64.6875 57.5 64.6875ZM55 82.8125C52.2386 82.8125 50 80.5739 50 77.8125C50 75.0511 52.2386 72.8125 55 72.8125C57.7614 72.8125 60 75.0511 60 77.8125C60 80.5739 57.7614 82.8125 55 82.8125Z"
      fill="currentcolor"
    />
  </svg>,
  "Exclamation",
);
