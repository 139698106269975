import { css, keyframes } from "@emotion/react";
import { theme } from "@polifonia/theme";
import type { CSSProperties } from "react";

const bars = keyframes`
  0%    { transform: scale(1,1); }
  50%   { transform: scale(1,0.3); }
  100%  { transform: scale(1,1); }
`;

const cssBarLoading = {
  wrapper: css({
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  }),
  spinner: css({
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
    width: "30px",
    height: "30px",
    "> div": {
      flex: 1,
      marginRight: "4px",
      height: "100%",
      borderRadius: "5px",
      animationName: bars,
      animationDuration: "1000ms",
      animationIterationCount: "infinite",
      animationTimingFunction: "cubic-bezier(0.785, 0.135, 0.15, 0.86)",
      ":last-child": {
        margin: 0,
      },
    },
  }),
  bar1: css({
    backgroundColor: theme.colors.blue060,
    animationDelay: "0ms",
  }),
  bar2: css({
    backgroundColor: theme.colors.blue050,
    animationDelay: "200ms",
  }),
  bar3: css({
    backgroundColor: theme.colors.blue040,
    animationDelay: "400ms",
  }),
  bar4: css({
    backgroundColor: theme.colors.blue030,
    animationDelay: "600ms",
  }),
};

interface BarLoadingProps {
  wrapperStyle?: CSSProperties;
}

export const BarLoading = ({ wrapperStyle }: BarLoadingProps) => (
  <div css={cssBarLoading.wrapper} style={wrapperStyle}>
    <div css={cssBarLoading.spinner}>
      <div css={cssBarLoading.bar1} />
      <div css={cssBarLoading.bar2} />
      <div css={cssBarLoading.bar3} />
      <div css={cssBarLoading.bar4} />
    </div>
  </div>
);
