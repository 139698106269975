import { featureFlagConfigs } from "@/config/featureFlagConfigs";
import type {
  BaseSingleSelectFeatureFlagConfig,
  BaseToggleFeatureFlagConfig,
} from "@/features/featureFlag/baseTypes";
import type { defineConfig } from "@/features/featureFlag/defineConfig";

/**
 * Instead of using `featureFlagConfigs` for common features, use this approach to
 * simplify type debugging. This is especially helpful when certain properties
 * or types are unused, resulting in them being of type `never`.
 */
const commonConfig = featureFlagConfigs as unknown as ReturnType<
  typeof defineConfig<{
    __toggle: BaseToggleFeatureFlagConfig;
    __singleSelect: BaseSingleSelectFeatureFlagConfig;
  }>
>;

export { commonConfig };
