import { memo } from "@chatbotgang/etude/react/memo";
import type { SvgIconProps } from "@mui/material/SvgIcon";
import SvgIcon from "@mui/material/SvgIcon";

export const KeyboardArrowDownIcon = memo(function KeyboardArrowDownIcon(
  props: SvgIconProps,
) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.91116 15.8255 8.91116 15.4765 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7092 15.0888 12.2819 15.0888 12.6309 14.7335Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
