import Alert from "@mui/material/Alert";
import { createContext } from "@polifonia/utils/react/createContext";
import type { ReactNode } from "react";
import { type FC } from "react";

import { BarLoading } from "@/components/Loading/BarLoading";
import { interlude, type InterludeTypes } from "@/interlude";

const unifyOrgsContext = createContext<InterludeTypes["Org"][]>({
  name: "UnifyOrgsContext",
});

const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const unifyOrgsQuery = interlude.unifyScopeSetting.useGetOrganization();

  if (unifyOrgsQuery.isLoading) return <BarLoading />;

  if (unifyOrgsQuery.isError)
    return <Alert severity="error">{unifyOrgsQuery.error.message}</Alert>;

  return (
    <unifyOrgsContext.Provider value={unifyOrgsQuery.data}>
      {children}
    </unifyOrgsContext.Provider>
  );
};

const plugin = {
  useUnifyOrgsContext: unifyOrgsContext.useContext,
};

const UnifyOrgsProvider = Object.assign(Provider, plugin);

export { UnifyOrgsProvider };
