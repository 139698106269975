import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { shallow } from "@polifonia/utils/zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import type { ModalProps } from "@/components/Modal";
import { Modal } from "@/components/Modal";
import {
  useEnabledSomeFeatureFlags,
  useFeatureFlag,
} from "@/features/featureFlag";
import { Feature } from "@/layout/DevMode/Feature";
import { registerClicks } from "@/layout/DevMode/register/registerClicks";
import { registerComboKeys } from "@/layout/DevMode/register/registerComboKeys";
// import { registerCommand } from "@/layout/DevMode/register/registerCommand";
import { registerTaps } from "@/layout/DevMode/register/registerTaps";

type Store = {
  opened: boolean;
  open: () => void;
  close: () => void;
};

const useStore = createWithEqualityFn<Store>()(
  (set) => ({
    opened: false,
    open: () => set({ opened: true }),
    close: () => set({ opened: false }),
  }),
  shallow,
);

function openDevMode() {
  useStore.getState().open();
}

registerComboKeys(openDevMode);
registerClicks(openDevMode);
registerTaps(openDevMode);
// registerCommand(useStore);

const cssDevMode = css`
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  width: min-content;
  height: min-content;
  align-items: flex-end;
  justify-content: flex-end;
  color: #8884;
  font-size: 4em;
  font-weight: 900;
  line-height: 1;
  pointer-events: none;

  &::before {
    content: "DevMode";
  }
`;

export const DevMode = memo(function DevMode() {
  const { opened, close } = useStore(
    useHandler(({ opened, close }) => ({
      opened,
      close,
    })),
  );
  const enabledSomeFeatureFlags = useEnabledSomeFeatureFlags();
  const alwaysHideDevModeHint = useFeatureFlag("alwaysHideDevModeHint");
  const handleClose = useHandler<ModalProps["handleClose"]>(
    (_event, reason) => {
      if (reason === "backdropClick") return;
      close();
    },
  );
  return (
    <>
      <Modal
        title="Feature Flags"
        open={opened}
        handleClose={handleClose}
        footer={null}
      >
        <Feature />
      </Modal>
      {!enabledSomeFeatureFlags || alwaysHideDevModeHint ? null : (
        <div css={cssDevMode} />
      )}
    </>
  );
});
