import { useTokenStore } from "@/features/auth/useTokenStore";
import { interlude } from "@/interlude";

export function useLoginMutation(
  options?: Parameters<typeof interlude.auth.useLogin>[1],
) {
  const signInMutation = interlude.auth.useLogin(
    {},
    {
      ...options,
      onSuccess(...args) {
        options?.onSuccess?.(...args);
        const token = args[0].token;
        useTokenStore.getState().setValue(token);
      },
    },
  );

  return signInMutation;
}
