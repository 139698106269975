import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { Typography } from "@/components/Typography";
import { CdhCard } from "@/pages/applications/components/CdhCard";
import { PageTitle } from "@/pages/components/PageTitle";

const cssCardContainer = css`
  display: grid;
  gap: 1rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(min(28rem, 100%), 1fr));
`;

export const ApplicationPage = memo(function ApplicationPage() {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t("applications.title")}</PageTitle>
      <Typography
        variant="h2"
        fontWeight={500}
        css={css`
          margin-bottom: 16px;
        `}
      >
        {t("applications.apps")}
      </Typography>
      <div css={cssCardContainer}>
        <CdhCard />
      </div>
    </>
  );
});
