import { DEFAULT_QUERY_RETRY_MAX_COUNT } from "@polifonia/env";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { ZodiosError } from "@zodios/core";
import { AxiosError, CanceledError } from "axios";

import { mutationErrorEventEmitter } from "@/features/error/mutationErrorEventEmitter";
import { logError } from "@/features/logger";
import { UnAuthError } from "@/interlude/interludeAxios";

/**
 * Legacy.
 *
 * This is for fetch aborting.
 */
function isAbortError(error: unknown): error is DOMException & {
  name: "AbortError";
} {
  return error instanceof DOMException && error.name === "AbortError";
}

const queryCache = new QueryCache({
  onError(error) {
    if (isAbortError(error)) return;
    if (error instanceof CanceledError) return;
    if (error instanceof ZodiosError) logError(error);
  },
});

const mutationCache = new MutationCache({
  onError(error) {
    if (isAbortError(error)) return;
    if (error instanceof CanceledError) return;
    if (error instanceof ZodiosError) {
      logError(error);
      return;
    }

    // Sync error with event so that error handling UI can process it
    mutationErrorEventEmitter.emit("error", error);
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      retry: (failureCount, error) => {
        if (error instanceof CanceledError) return false;
        if (error instanceof ZodiosError) return false;
        if (error instanceof UnAuthError) return false;
        if (error instanceof AxiosError) {
          if (!error.response || !error.request) return false;
          const is4xxError =
            error.response.status >= 400 && error.response.status < 500;
          if (is4xxError) return false;
        }
        return failureCount < DEFAULT_QUERY_RETRY_MAX_COUNT;
      },
    },
    mutations: {
      retry: () => false,
    },
  },
  queryCache,
  mutationCache,
});
