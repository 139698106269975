import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  OrgSchema,
  UnificationKeysSchema,
  UnifyScopeSettingSchema,
} from "../models";

const api = makeApi([
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#f0ad462e7a344a5aac8425d86d0ab732)
     */
    alias: "integrateCdh",
    method: "post",
    path: "/api/v1/applications/cdh/integrate",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          unifyKeys: z.array(UnificationKeysSchema),
        }),
      },
    ],
    response: UnifyScopeSettingSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#3364bddfff774da99d0e1b801a05219b)
     */
    alias: "disconnectCdh",
    method: "post",
    path: "/api/v1/applications/cdh/disconnect",
    // 204 No Content with z.void() or z.never() not working here
    response: z.unknown(),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#d7bde1c2f9094bccb7d17e04c66443da)
     */
    alias: "getUnifyScopeSetting",
    method: "get",
    path: "/api/v1/applications/cdh/unify-scope-setting",
    response: UnifyScopeSettingSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#b4d46951f6f440d2acdef1f1fd66c824)
     */
    alias: "getOrganization",
    method: "get",
    path: "/api/v1/applications/cdh/organizations",
    response: OrgSchema.array(),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#f7629e34288841f9b478d74a0f390294)
     */
    alias: "updateUnifyKeys",
    method: "put",
    path: "/api/v1/applications/cdh/unify-scope-setting/unify-keys",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          unifyKeys: z.array(UnificationKeysSchema),
        }),
      },
    ],
    // ignore response
    response: z.unknown(),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#6df972b720f24a2181e25b43256bcdda)
     */
    alias: "getUnifyMergeState",
    method: "get",
    path: "/api/v1/applications/cdh/unify-scope-setting/merge-state",
    response: z.object({
      unifyScopeId: z.number().int(),
      isFinished: z.boolean(),
    }),
  },
]);

export { api };
