import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Alert from "@mui/material/Alert";
import { type FC, useMemo } from "react";

import { Trans } from "@/features/i18n/Trans";
import { useProductNotActivated } from "@/features/unify-scope-setting/ProfileUnificationWarning/useProductNotActivated";

export const RequireActivate: FC = () => {
  const productNotActivated = useProductNotActivated();

  const product = useMemo(
    function computeProduct() {
      return productNotActivated.map((s) => s.toUpperCase()).join(", ");
    },
    [productNotActivated],
  );

  if (productNotActivated.length === 0) return null;

  return (
    <Alert severity="warning" icon={<InfoOutlinedIcon />}>
      <Trans
        i18nKey="feature.activationWarning.message"
        values={{
          product,
        }}
        components={{
          strong: <strong />,
        }}
      />
    </Alert>
  );
};
