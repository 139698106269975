import { toNumber } from "@chatbotgang/etude/pitch-shifter/number";

import type { NumberFormatPreset } from "@/components/NumberFormat/types";

/**
 * A map of preset options for the Intl.NumberFormat constructor
 * These are loosely based on the utility functions found under `utils/number`
 * Some sensible defaults have been chosen
 */
export const numberFormatPresetMap: Record<
  NumberFormatPreset,
  Intl.NumberFormatOptions
> = {
  count: {
    style: "decimal",
  },
  percent: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "percent",
  },
  /**
   * Note: in the existing implementation revenue is just a count with some default options
   * Revenue isn't `currency` as we aren't currently displaying actual currencies in the UI
   */
  revenue: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: "decimal",
  },
};

/**
 * Check whether the value should be formatted at all; will return the fallback text if not
 */
export const shouldFormatNumber = (value: unknown): value is number => {
  if (value === null || value === undefined) {
    return false;
  }

  const valueNumber = toNumber(value);

  return !Number.isNaN(valueNumber) && Number.isFinite(valueNumber);
};
