import { createTheme } from "@mui/material/styles";
import { colors } from "@polifonia/theme/colors";

const theme = {
  colors,
  shape: {
    borderRadius: "4px",
  },
  shadows: [
    `0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05)`,
  ],
} as const;

type Theme = typeof theme;

const defaultMuiShadows = createTheme().shadows;

const muiTheme = createTheme({
  typography: {
    // We configure the font family in the global CSS by languages
    fontFamily: "inherit",
  },
  palette: {
    primary: {
      main: theme.colors.primary,
    },
  },
  shadows: [...defaultMuiShadows.fill(theme.shadows[0], 0)],
  components: {
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: "8px 12px",
          boxShadow: "none",
          borderRadius: theme.shape.borderRadius,
        },
        outlined: {
          padding: 0,
          border: "none",
        },
        outlinedInfo: {
          color: theme.colors.staticFgPrimary,
          svg: {
            color: theme.colors.staticFgPrimary,
          },
        },
        outlinedSuccess: {
          color: theme.colors.staticFgPositive,
          svg: {
            color: theme.colors.staticFgPositive,
          },
        },
        standardInfo: {
          backgroundColor: theme.colors.staticBgPrimary,
          color: theme.colors.staticFgPrimary,
          svg: {
            color: theme.colors.staticFgPrimary,
          },
        },
        standardSuccess: {
          backgroundColor: theme.colors.staticBgPositive,
          color: theme.colors.staticFgPositive,
          svg: {
            color: theme.colors.staticFgPositive,
          },
        },
        standardWarning: {
          backgroundColor: theme.colors.staticBgAttentive,
          color: theme.colors.staticFgAttentive,
          svg: {
            color: theme.colors.staticFgAttentive,
          },
        },
        standardError: {
          backgroundColor: theme.colors.staticBgNegative,
          color: theme.colors.staticFgNegative,
          svg: {
            color: theme.colors.staticFgNegative,
          },
        },
        icon: {
          marginRight: "4px",
          padding: "2px",
          svg: {
            fontSize: "1rem",
          },
        },
        message: {
          padding: "0",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          // I don't know why global typography fontFamily doesn't work. I need this additional line to override it.
          fontFamily: "inherit",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          lineHeight: 1.42857,
          margin: "4px 0 0",
          color: theme.colors.neutral070,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          height: "24px",
        },
        label: {
          padding: "2px 8px",
        },
        deleteIcon: {
          fontSize: "14px",
          color: "currentcolor",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          margin: "0 4px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "7px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
  },
});

export { muiTheme, theme };
export type { Theme };
