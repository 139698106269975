import { logErrorWithCause } from "@chatbotgang/etude/debug/logErrorWithCause";
import { DEVELOPMENT_MODE, ENV_TEST } from "@polifonia/env";
import { captureException, captureMessage } from "@sentry/browser";
import objectInspect from "object-inspect";

export const logError = <T = Error>(error: T) => {
  if (DEVELOPMENT_MODE && !ENV_TEST) logErrorWithCause("[logError]", error);

  if (error instanceof Error) {
    return captureException(error);
  }
  return captureMessage(`Caught a non-error thrown: ${objectInspect(error)}`);
};
