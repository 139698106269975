import { fc } from "@chatbotgang/etude/react/fc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { theme } from "@polifonia/theme";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import urlJoin from "url-join";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { Button } from "@/components/Button";
import { NumberFormat } from "@/components/NumberFormat";
import { Tooltip } from "@/components/Tooltip";
import { Typography } from "@/components/Typography";
import { trackGaEvent } from "@/features/ga/trackGaEvent";
import { interlude } from "@/interlude";
import { ThresholdSettingModal } from "@/pages/billing/ThresholdSettingModal";
import { PageTitle } from "@/pages/components/PageTitle";

export const BillingPage = fc(function BillingPage() {
  const { t } = useTranslation();

  const [alertSettingModalOpen, setAlertSettingModalOpen] = useState(false);

  const balanceQuery = interlude.balance.useGetBalance();

  const meQuery = interlude.auth.useGetMe();

  const topUpUrl = useMemo(
    () =>
      meQuery.data?.organization.name
        ? urlJoin(
            "https://www.surveycake.com/s/dlqV3",
            `?aka_in=${meQuery.data.organization.name}`,
          )
        : null,
    [meQuery.data?.organization.name],
  );

  return (
    <>
      <PageTitle>{t("billing.title")}</PageTitle>
      <Card
        sx={{ display: "flex", alignItems: "center", width: "100%" }}
        variant="outlined"
      >
        <CardContent>
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{ marginBottom: "8px" }}
          >
            <Typography variant="body">
              {t("billing.availableBalance.title")}
            </Typography>
            <Tooltip
              title={
                <>
                  <div>{t("billing.availableBalance.tooltip.content")}</div>
                  <Button
                    sx={{ padding: "4px 0 0" }}
                    href={t("billing.availableBalance.tooltip.moreLink")}
                    variant="transparent"
                    endIcon={null}
                  >
                    {t("billing.availableBalance.tooltip.more")}
                  </Button>
                </>
              }
              arrow
            >
              <HelpOutlineIcon
                fontSize="inherit"
                sx={{ color: theme.colors.staticFgNote }}
              />
            </Tooltip>
          </Stack>
          <Typography variant="value">
            {balanceQuery.isLoading ? (
              <CircularProgress />
            ) : (
              <NumberFormat
                nullishValue={EMPTY_STRING_PLACEHOLDER}
                value={balanceQuery.data?.availableBalance}
              />
            )}
          </Typography>
        </CardContent>
        <CardContent sx={{ marginLeft: "auto", display: "flex", gap: "10px" }}>
          <Button
            variant="secondary"
            onClick={() => setAlertSettingModalOpen(true)}
            disabled={!balanceQuery.data}
          >
            {t("billing.alertSetting")}
          </Button>
          <Button
            variant="primary"
            {...(topUpUrl ? { href: topUpUrl } : {})}
            disabled={!topUpUrl}
            endIcon={null}
            onClick={() => {
              trackGaEvent("billingMaacTopUpClick");
            }}
          >
            {t("billing.deposit")}
          </Button>
        </CardContent>
      </Card>
      <ThresholdSettingModal
        open={alertSettingModalOpen}
        closeModal={() => setAlertSettingModalOpen(false)}
      />
    </>
  );
});
