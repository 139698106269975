import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import CheckIcon from "@mui/icons-material/Check";
import type { AutocompleteProps } from "@mui/material/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import type { TextFieldProps } from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import { theme } from "@polifonia/theme";
import { useMemo } from "react";

import { KeyboardArrowDownIcon } from "@/components/Icons/KeyboardArrowDownIcon";
import { Tag } from "@/components/Tag";

export type SelectValue = {
  label?: React.ReactNode;
  value: string | number;
};

export interface MultiSelectProps<Value extends SelectValue>
  extends Omit<
      AutocompleteProps<Value, true, false, false>,
      | "multiple"
      | "disableCloseOnSelect"
      | "renderTags"
      | "renderInput"
      | "popupIcon"
      | "disableClearable"
      | "freeSolo"
      | "getOptionLabel"
      | "renderOption"
      | "onChange"
      | "value"
    >,
    Pick<TextFieldProps, "helperText" | "error"> {
  placeholder?: string;
  maxCount?: number;
  value?: Array<Value["value"]>;
  onChange?: (value: Array<Value["value"]>) => void;
  showCount?: boolean;
}

/**
 * TODO: currently, options label only accepts string, need to fix this
 */
export const MultiSelect = function MultiSelect<Value extends SelectValue>({
  value,
  onChange,
  options,
  placeholder = "",
  helperText,
  error,
  maxCount = Infinity,
  showCount = false,
  ...props
}: MultiSelectProps<Value>) {
  const autoCompleteValue = useMemo(() => {
    return (
      value?.map((v) => options.find((o) => o.value === v)).filter(Boolean) ??
      []
    );
  }, [value, options]);

  const handleChange = useHandler<
    AutocompleteProps<Value, true, false, false>["onChange"]
  >((_event, value, reason) => {
    if (reason === "selectOption" && value.length > maxCount) {
      return;
    }

    onChange?.(value.map((v) => v.value));
  });

  return (
    <>
      <Autocomplete<Value, true, false, false>
        multiple
        disableCloseOnSelect
        value={autoCompleteValue}
        onChange={handleChange}
        options={options}
        renderTags={(values, getTagProps) =>
          values.map(({ value, label }, index) => {
            const { onDelete } = getTagProps({ index });
            return (
              <Tag
                key={value}
                label={label || value}
                onDelete={onDelete}
                sx={{ margin: 0.25, height: "24px" }}
              />
            );
          })
        }
        renderInput={(params) => (
          // @ts-expect-error The types of 'InputLabelProps.className' are incompatible between these types.
          <TextField
            {...params}
            size="medium"
            variant="outlined"
            placeholder={placeholder}
            error={error}
            helperText={
              <span
                css={css({
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                })}
              >
                <span>{helperText}</span>
                {!showCount ||
                  (maxCount === Infinity ? null : (
                    <span>{`${autoCompleteValue.length}/${maxCount}`}</span>
                  ))}
              </span>
            }
            sx={{
              ".MuiInputBase-root": {
                gap: "10px",
              },
              ".MuiOutlinedInput-root:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: theme.colors.neutral030,
                },
            }}
          />
        )}
        popupIcon={<KeyboardArrowDownIcon />}
        renderOption={(props, option, { selected }) => {
          const disabled = !selected && value && value.length >= maxCount;

          return (
            // @ts-expect-error props are incompatible
            <MenuItem
              {...props}
              key={option.value}
              value={option}
              disabled={disabled}
              sx={{
                "&.MuiAutocomplete-option": {
                  fontSize: "0.875rem",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                },
                "&.Mui-disabled": {
                  opacity: 0.3,
                },
              }}
            >
              <div>{option.label}</div>
              {selected ? (
                <CheckIcon color="primary" sx={{ marginLeft: "auto" }} />
              ) : null}
            </MenuItem>
          );
        }}
        sx={{
          ".MuiAutocomplete-inputRoot": {
            padding: "2px 6px",
            borderRadius: theme.shape.borderRadius,
          },
          ".MuiOutlinedInput-root .MuiAutocomplete-input": {
            padding: "0 4px",
          },
        }}
        {...props}
      />
    </>
  );
};
