import { useCountDown } from "@/components/CountDown/useCountDown";

const CountDown = function CountDown({ targetDate }: { targetDate: Date }) {
  const countDown = useCountDown(targetDate);
  if (!countDown) return null;
  const { days, hours, minutes, seconds } = countDown;

  return (
    <>
      {days}d, {hours}h, {minutes}m, {seconds}s
    </>
  );
};

export { CountDown };
