import { OrgSchema } from "@polifonia/interlude/models";
import { difference } from "lodash-es";
import { useMemo } from "react";

import { UnifyOrgsProvider } from "@/features/unify-scope-setting/ProfileUnificationWarning/Provider";

const ALL_SOURCES = OrgSchema.shape.source.options;

export const useProductNotActivated = () => {
  const orgs = UnifyOrgsProvider.useUnifyOrgsContext();

  return useMemo(
    function computeProduct() {
      const source = orgs.map((org) => org.source);
      return difference(ALL_SOURCES, source);
    },
    [orgs],
  );
};
