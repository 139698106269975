import type { UseMutationOptions } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import type {
  Method,
  ZodiosBodyByPath,
  ZodiosEndpointDefinition,
  ZodiosEndpointDefinitions,
  ZodiosError,
  ZodiosErrorByPath,
  ZodiosInstance,
  ZodiosPathsByMethod,
  ZodiosRequestOptionsByPath,
  ZodiosResponseByPath,
} from "@zodios/core";
import { Zodios } from "@zodios/core";
import type {
  IfEquals,
  Narrow,
  ReadonlyDeep,
  RequiredKeys,
  UndefinedIfNever,
} from "@zodios/core/lib/utils.types";
import type { ZodiosHooksInstance } from "@zodios/react";
import { ZodiosHooks } from "@zodios/react";
import { AxiosError } from "axios";
import { objectEntries, objectFromEntries, objectKeys } from "tsafe";

import type { generateApi } from "./generateApi";
import { safeInvalidateQuery } from "./useSafeInvalidateQuery";

/**
 * **Start**
 *
 * These types are copied from `@zodios/react/lib/index.d.ts` because they are
 * not exported.
 */

type UnknownIfNever<T> = IfEquals<T, never, unknown, T>;
type Errors<T> = Error | ZodiosError | AxiosError<T>;

type MutationOptions<
  Api extends ZodiosEndpointDefinition[],
  M extends Method,
  Path extends ZodiosPathsByMethod<Api, M>,
> = Omit<
  UseMutationOptions<
    Awaited<ZodiosResponseByPath<Api, M, Path>>,
    Errors<UnknownIfNever<ZodiosErrorByPath<Api, M, Path, number>>>,
    UndefinedIfNever<ZodiosBodyByPath<Api, M, Path>>
  >,
  "mutationFn"
>;

/**
 * **End**
 *
 * These types are copied from `@zodios/react/lib/index.d.ts` because they are
 * not exported.
 */

type AffectedApisOptions<ApiName extends string> = {
  [Name in ApiName]?: {
    [AffectedApiName in Exclude<ApiName, Name>]?: true;
  };
};

/**
 * Get all names to invalidate from current api name and affected apis options.
 */
function getAffectedApiNames<ApiName extends string>(
  currentApiName: ApiName,
  affectedApis?: AffectedApisOptions<ApiName>,
): ApiName[] {
  const otherApis: ApiName[] =
    affectedApis &&
    currentApiName in affectedApis &&
    affectedApis[currentApiName]
      ? objectKeys(affectedApis[currentApiName] as Record<ApiName, true>)
      : [];
  return [currentApiName, ...otherApis];
}

/**
 * From `ZodiosHooks`.
 *
 * @see {@link ZodiosHooks}
 */
type ZodiosHooksOptions = {
  shouldAbortOnUnmount?: boolean;
};

type ReactOptions<ApiRecord extends Record<string, ZodiosEndpointDefinitions>> =
  {
    affectedApis?: AffectedApisOptions<keyof ApiRecord & string>;
    zodiosHooksOptions?: ZodiosHooksOptions;
  };

/**
 * @param apiHooks - The zodios hooks instance to inject affected apis.
 * @param affectedApiNames - All api names to invalidate.
 */
function injectAffectedApis<Api extends ZodiosEndpointDefinitions>(
  apiHooks: ZodiosHooksInstance<Api>,
  affectedApiNames: Array<string>,
) {
  const thisUseMutation = apiHooks.useMutation;

  const newUseMutation: typeof thisUseMutation = function useMutation<
    M extends Method,
    Path extends ZodiosPathsByMethod<Api, M>,
    TConfig extends ZodiosRequestOptionsByPath<Api, M, Path>,
  >(
    method: M,
    path: Path,
    ...[config, mutationOptions]: RequiredKeys<TConfig> extends never
      ? [
          config?: ReadonlyDeep<TConfig>,
          mutationOptions?: MutationOptions<Api, M, Path>,
        ]
      : [
          config: ReadonlyDeep<TConfig>,
          mutationOptions?: MutationOptions<Api, M, Path>,
        ]
  ) {
    const queryClient = useQueryClient();
    // @ts-expect-error -- FIXME: Type '{ toString?: (() => string) | ((() => string) & (() => string)) | (() => string); toLocaleString?: (() => string) | (() => string); }' is not assignable to type 'ReadonlyDeep<TConfig>'.ts(2322)
    const newConfig: ReadonlyDeep<TConfig> = {
      ...config,
    };
    const newMutationOptions: MutationOptions<Api, M, Path> = {
      ...mutationOptions,
      onSettled: async (...args) => {
        const [, error] = args;
        if (
          !error ||
          (error instanceof AxiosError &&
            error.response &&
            typeof error.response.status &&
            !(error.response.status === 401 || error.response.status >= 500))
        ) {
          await Promise.all(
            affectedApiNames.map(
              async (api) =>
                await safeInvalidateQuery(queryClient, {
                  queryKey: [
                    {
                      api,
                    },
                  ],
                }),
            ),
          );
        }
        if (!mutationOptions?.onSettled) return;
        return await mutationOptions.onSettled(...args);
      },
    };
    return thisUseMutation.call(
      apiHooks,
      method,
      path,
      // Cspell:disable-next-line
      // @ts-expect-error -- FIXME: Argument of type '[ReadonlyDeep<TConfig>, MutationOptions<Api, M, Path>]' is not assignable to parameter of type '[...RequiredKeys<Simplify<Simplify<Pick<Partial<PickDefined<{ params: IfEquals<{ [K in PathParamNames<ZodiosPathsByMethod<Api, Method>, never>]: MapSchemaParameters<FilterArrayByValue<FilterArrayByValue<Api, { method: Method; path: ZodiosPathsByMethod<...>; }, []>[number]["parameters"], { ...; }, []>, true, {}> exte...'. Source has 2 element(s) but target allows only 1.ts(2345)
      newConfig,
      newMutationOptions,
    );
  };

  apiHooks.useMutation = newUseMutation.bind(apiHooks);
}

/**
 * @example
 * ```ts
 * const api = generateApi( ... );
 * const hooks = react(api)({
 *   affectedApis: {
 *     user: {
 *       tag: true,
 *     },
 *     tag: {
 *       user: true,
 *     }
 *   }
 * });
 * ```
 *
 * @param api - api generated by `generateApi`
 * @see {@link generateApi}
 * @param zodiosHooksOptions - options.zodiosHooksOptions, options for `@zodios/react`
 * @returns
 */
function react<ApiRecord extends Record<string, ZodiosEndpointDefinitions>>(
  api: {
    [Key in keyof ApiRecord]: ZodiosInstance<Narrow<ApiRecord[Key]>>;
  },
  {
    zodiosHooksOptions: zodiosHooksOptionsForAll,
  }: {
    zodiosHooksOptions?: ZodiosHooksOptions;
  } = {},
): ({ affectedApis, zodiosHooksOptions }?: ReactOptions<ApiRecord>) => {
  [Key in keyof ApiRecord]: ZodiosHooksInstance<Narrow<ApiRecord[Key]>>;
} {
  /**
   *
   * @param options -
   * @param affectedApis - options.affectedApis, if you want to invalidate queries of other apis
   * when a mutation is done, you can specify it here.
   *
   * For example, if you have an api `user` and an api `post`, and you want to
   * invalidate queries of `post` when a mutation is done on `user`, you can do:
   *
   * @example
   * ```ts
   * const hooks = react(api)({
   *   affectedApis: {
   *     user: {
   *       post: true,
   *     },
   *   },
   * });
   * ```
   * @param zodiosHooksOptions - options.zodiosHooksOptions, options for `@zodios/react`
   */
  return function react({ affectedApis, zodiosHooksOptions } = {}) {
    const entries: Array<
      [string, ZodiosHooksInstance<ZodiosEndpointDefinitions>]
    > = objectEntries(api).map(
      <Key extends keyof ApiRecord, Api extends ZodiosEndpointDefinitions>([
        name,
        apiClient,
      ]: [Key, ZodiosInstance<Narrow<Api>>]) => {
        if (typeof name !== "string") throw new Error("name must be a string");
        if (!(apiClient instanceof Zodios))
          throw new Error("apiClient must be a Zodios instance");
        const apiHooks = new ZodiosHooks(
          name,
          apiClient,
          zodiosHooksOptionsForAll?.shouldAbortOnUnmount &&
          zodiosHooksOptions?.shouldAbortOnUnmount
            ? {
                shouldAbortOnUnmount: true,
              }
            : undefined,
        );

        injectAffectedApis(apiHooks, getAffectedApiNames(name, affectedApis));

        return [name, apiHooks];
      },
    ) as Array<[string, ZodiosHooksInstance<ZodiosEndpointDefinitions>]>;
    const object = objectFromEntries(entries) as {
      [Key in keyof ApiRecord]: ZodiosHooksInstance<Narrow<ApiRecord[Key]>>;
    };
    return object;
  };
}

export { react };
