import type { F } from "ts-toolbelt";

/**
 * Define a value with a specific type.
 *
 * TODO: should update this function to Etude
 *
 * @example
 *
 * ```ts
 * const value = define<Record<string, string>>({ foo: 'bar' });
 * //    ^? const value: Record<string, string>
 * ```
 *
 * We can narrow the type of `value` by passing it to the function returned by `define`:
 *
 * ```ts
 * const value = define<Record<string, string>>()({ foo: 'bar' });
 * //    ^? const value: { foo: "bar"; }
 * ```
 *
 * Please note that if the type is too complex, TypeScript may fail to narrow
 * the type. For example, when using a generic function with a union:
 *
 * ```ts
 * type ComplexType<T extends 'string' | 'number'> = {
 *   type: T;
 *   render: ((name: T) => string) | ReactNode;
 * };
 *
 * const result = define<ComplexType<'string'>>({
 *   type: 'string',
 *   render: (name) => name,
 *   //       ^? (parameter) name: "string"
 * });
 *
 * const result = define<ComplexType<'string'>>()({
 *   type: 'string',
 *   render: (name) => name, // Error: Parameter 'name' implicitly has an 'any' type.
 *   //       ^? (parameter) name: any
 * });
 * ```
 */
function define<T>(value: T): T;
function define<T>(): <TT extends T>(value: F.Narrow<TT>) => TT;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function define<T>(...args: Array<any>) {
  if (args.length > 0) return args[0];
  return <TT extends T>(value: F.Narrow<TT>) => value;
}

export { define };
