import { createGaEventRecord } from "@/features/ga/utils";

export const gaEventRecords = {
  ...createGaEventRecord({
    billingMaacTopUpClick: {
      name: "billing_maac_top_up_click",
      description: "User clicks on the MAAC top-up button on the billing page",
    },
    billingCaacTopUpClick: {
      name: "billing_caac_top_up_click",
      description: "User clicks on the CAAC top-up button on the billing page",
    },
  }),
};
