/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-restricted-imports -- The only file allowed to import Trans from react-i18next is this one.
import { Trans as ReactI18nextTrans, useTranslation } from "react-i18next";

const Trans: typeof ReactI18nextTrans = function Trans(props: any) {
  // This hook is used to force re-rendering of the component when the language changes.
  useTranslation();
  return <ReactI18nextTrans {...props} />;
} as any;

export { Trans };
