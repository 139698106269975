import { shallow } from "@polifonia/utils/zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

const useStore = createWithEqualityFn<{
  now: Date;
}>()(
  () => ({
    now: new Date(),
  }),
  shallow,
);

const fps = 1000 / 60;

function updateNow() {
  useStore.setState({ now: new Date() });
  setTimeout(updateNow, fps);
}

updateNow();

/**
 * Retrieves the current time.
 *
 * Use this hook with caution, as it can lead to the component re-rendering
 * with every frame.
 */
export function useNow() {
  return useStore((state) => state.now);
}
