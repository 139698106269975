import type { FC } from "react";

import { Tooltip, type TooltipProps } from "@/components/Tooltip";
import { Trans } from "@/features/i18n/Trans";

type ProfileMergingTooltipProps = Omit<TooltipProps, "title" | "placement"> & {
  disabled?: boolean | undefined;
};

const ProfileMergingTooltip: FC<ProfileMergingTooltipProps> = ({
  disabled,
  ...props
}) => {
  return (
    <Tooltip
      title={
        disabled ? (
          ""
        ) : (
          <Trans i18nKey="cdh.unification.unifyKey.edit.disabled.tooltip" />
        )
      }
      placement="bottom"
      {...props}
    />
  );
};

export { ProfileMergingTooltip };
