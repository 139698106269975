export const langStrategies = {
  isZHLang: <T>(lang: string, returnValue: T) => {
    if (lang.startsWith("zh")) {
      return returnValue;
    }
    return undefined;
  },
  isENLang: <T>(lang: string, returnValue: T) => {
    if (lang.startsWith("en")) {
      return returnValue;
    }
    return undefined;
  },
  isTHLang: <T>(lang: string, returnValue: T) => {
    if (lang.startsWith("th")) {
      return returnValue;
    }
    return undefined;
  },
  isJALang: <T>(lang: string, returnValue: T) => {
    if (lang.startsWith("ja")) {
      return returnValue;
    }
    return undefined;
  },
  default: <T>(_lang: string, returnValue: T) => {
    return returnValue;
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class LangsLocalesUtils<T extends () => any> {
  private _langStrategies: T[] = [];

  constructor(strategies: T[]) {
    this._langStrategies = strategies;
  }

  get langStrategies() {
    return this._langStrategies;
  }

  getResult(): ReturnType<T> | undefined {
    for (let index = 0; index < this._langStrategies.length; index++) {
      const result = this._langStrategies[index]?.();
      if (result !== undefined) return result;
    }

    return undefined;
  }
}
