import { UnificationKeysSchema } from "@polifonia/interlude/models";
import { constant } from "lodash-es";
import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { SelectValue } from "@/components/MultiSelect";
import type { MultiSelectProps } from "@/components/MultiSelect";
import { MultiSelect } from "@/components/MultiSelect";
import { unifyKeyLabelTranslationMap } from "@/features/unify-scope-setting/unifyKeyLabelTranslationMap";
import type { InterludeTypes } from "@/interlude";

export type UnifyKeySelectValue = SelectValue & {
  value: InterludeTypes["UnificationKeys"];
};

type UnifyKeySelectorProps = Omit<
  MultiSelectProps<UnifyKeySelectValue>,
  "options"
> & {
  filter?: (key: InterludeTypes["UnificationKeys"]) => boolean;
};

export const UnifyKeySelector: FC<UnifyKeySelectorProps> = ({
  filter = constant(true),
  maxCount = 3,
  ...props
}) => {
  const { t } = useTranslation();
  const options = useMemo<MultiSelectProps<UnifyKeySelectValue>["options"]>(
    function getOptions() {
      return UnificationKeysSchema.options.filter(filter).map((key) => ({
        value: key,
        label: t(unifyKeyLabelTranslationMap[key]),
      }));
    },
    [filter, t],
  );

  return (
    <MultiSelect<UnifyKeySelectValue>
      maxCount={maxCount}
      {...props}
      options={options}
    />
  );
};
