import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { useNow } from "@polifonia/utils/react/useNow";

/**
 * Get the countdown to a target date. Returns null if the target date is
 * invalid.
 */
export function useCountDown(targetDate: Date) {
  const now = useNow();
  if (isInvalidDate(targetDate)) return null;

  const diff = targetDate.getTime() - now.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / (1000 * 60)) % 60);
  const seconds = Math.floor((diff / 1000) % 60);
  return {
    days,
    hours,
    minutes,
    seconds,
  };
}
