import { fakeT } from "@polifonia/utils/react/fakeT";

import type { InterludeTypes } from "@/interlude";

const t = fakeT;

export const unifyKeyLabelTranslationMap: Record<
  InterludeTypes["UnificationKeys"],
  string
> = {
  display_email: t("feature.unifyKey.displayEmail.label"),
  custom_id: t("feature.unifyKey.customId.label"),
  connect_id: t("feature.unifyKey.connectId.label"),
  display_mobile: t("feature.unifyKey.displayMobile.label"),
  line_id: t("feature.unifyKey.lineId.label"),
};
