/**
 * i18n-ally helper
 *
 * @example
 * ```ts
 * const t = fakeT;
 * t('demo.translationKey') // it just return the passed key, but i18n-ally can detect it and display the tranlsation value.
 * ```
 */
export function fakeT<Key extends string>(key: Key): Key {
  return key;
}
