import { fc } from "@chatbotgang/etude/react/fc";

import { Typography } from "@/components/Typography";

export const PageTitle = fc(function PageTitle({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Typography variant="h1" style={{ marginBottom: 48 }}>
      {children}
    </Typography>
  );
});
