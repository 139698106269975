import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { NumberFormat } from "@/components/NumberFormat";
import type { NumberFormatProps } from "@/components/NumberFormat/types";
import {
  numberFormatPresetMap,
  shouldFormatNumber,
} from "@/components/NumberFormat/utils";
import { getLocaleFromLanguageCode } from "@/features/i18n/i18n";

/**
 * A locales-aware hook used to format numbers for display
 * This handles common defaults without getting too much in the way of using browser APIs
 * Original instance is also returned in case you need conditional operation based on resolved options
 * Most of the time you should use the {@link NumberFormat} component since the component handles `font-variant-numeric`
 *
 * @example
 * ```tsx
 * const { numberFormat: formatCount } = useNumberFormat({
 *   numberFormatPreset: 'count',
 * });
 * const { numberFormat: revenueNumberFormat } = useNumberFormat({
 *   numberFormatPreset: 'revenue',
 * });
 * const { numberFormat: formatPercentFixed } = useNumberFormat({
 *   numberFormatPreset: 'percent',
 *   numberFormatOptions: {
 *     // Optional, any Intl.NumberFormatOptions
 *   },
 * });
 * ```
 */
export const useNumberFormat = ({
  numberFormatPreset = "count",
  nullishValue,
  numberFormatLocale,
  numberFormatOptions,
}: NumberFormatProps): {
  numberFormat: (value: unknown) => string;
  numberFormatInstance: Intl.NumberFormat;
} => {
  const { i18n } = useTranslation();

  const locale = getLocaleFromLanguageCode(i18n.language);

  const numberFormatInstance = useMemo(() => {
    const options = {
      ...numberFormatPresetMap[numberFormatPreset],
      ...numberFormatOptions,
    };

    return new Intl.NumberFormat(numberFormatLocale ?? locale, options);
  }, [numberFormatPreset, locale, numberFormatLocale, numberFormatOptions]);

  const numberFormat = useCallback(
    (value: unknown) => {
      if (!shouldFormatNumber(value)) {
        return (
          nullishValue ??
          `${EMPTY_STRING_PLACEHOLDER}${numberFormatPreset === "percent" ? "%" : ""}`
        );
      }
      return numberFormatInstance.format(value);
    },
    [nullishValue, numberFormatInstance, numberFormatPreset],
  );

  return {
    numberFormat,
    numberFormatInstance,
  };
};
