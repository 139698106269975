import { UnifyOrgsProvider } from "@/features/unify-scope-setting/ProfileUnificationWarning/Provider";
import { RequireActivate } from "@/features/unify-scope-setting/ProfileUnificationWarning/RequireActivate";
import { UnifyScope } from "@/features/unify-scope-setting/ProfileUnificationWarning/UnifyScope";
import { useProductNotActivated } from "@/features/unify-scope-setting/ProfileUnificationWarning/useProductNotActivated";

export const ProfileUnificationWarning = {
  Provider: UnifyOrgsProvider,
  UnifyScope,
  RequireActivate,
};
export { useProductNotActivated };
