import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import Alert from "@mui/material/Alert";
import type { CheckboxProps } from "@mui/material/Checkbox";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { theme } from "@polifonia/theme";
import useSwitch from "@react-hook/switch";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useSnackbar } from "notistack";
import { type FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { DiscList } from "@/components/DiscList";
import { Modal } from "@/components/Modal";
import { Typography } from "@/components/Typography";
import { interlude } from "@/interlude";
import { ProfileMergingTooltip } from "@/pages/applications/pages/cdh/components/ProfileMergingTooltip";

dayjs.extend(duration);

const COUNT_DOWN_MS = dayjs.duration({ seconds: 5 }).asMilliseconds();

export const DisconnectCdh: FC = () => {
  const { t } = useTranslation();
  const [open, toggle] = useSwitch();
  const [openFailed, toggleFailed] = useSwitch();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const mergingStateQuery = interlude.unifyScopeSetting.useGetUnifyMergeState();
  const disconnectMutation = interlude.unifyScopeSetting.useDisconnectCdh(
    undefined,
    {
      onSuccess() {
        cleanup();
        enqueueSnackbar(t("common.updatedSuccessfully"), {
          variant: "success",
        });
        navigate({
          to: "/applications",
        });
      },
      onError() {
        toggle.off();
        toggleFailed.on();
      },
    },
  );
  const handleDisconnect = useHandler(function disconnectCdh() {
    if (disconnectMutation.isLoading) return;

    disconnectMutation.mutate(undefined);
  });

  const handleCheckboxChange = useHandler<CheckboxProps["onChange"]>(
    function handleCheckboxChange(event) {
      setCheckboxChecked(event.target.checked);
    },
  );

  const handleCancel = useHandler(function handleCancel() {
    if (disconnectMutation.isLoading) return;

    cleanup();
  });

  const cleanup = useHandler(function cleanup() {
    toggle.off();
    toggleFailed.off();
    setCheckboxChecked(false);
  });

  if (mergingStateQuery.isError)
    return <Alert severity="error">{mergingStateQuery.error.message}</Alert>;

  if (mergingStateQuery.isLoading)
    return (
      <Button variant="plain" loading disabled>
        <Trans i18nKey="cdh.unification.disconnect.label" />
      </Button>
    );

  return (
    <div>
      <ProfileMergingTooltip disabled={mergingStateQuery.data.isFinished}>
        <span>
          <Button
            variant="plain"
            onClick={toggle.on}
            disabled={!mergingStateQuery.data.isFinished}
          >
            <Trans i18nKey="cdh.unification.disconnect.label" />
          </Button>
        </span>
      </ProfileMergingTooltip>
      <Modal
        open={open}
        keepMounted={false}
        handleClose={handleCancel}
        title={t("cdh.unification.disconnect.modal.title")}
        footer={
          <Stack direction="row" justifyContent="flex-end" gap="16px">
            <Button variant="plain" onClick={handleCancel}>
              {t("common.cancel")}
            </Button>
            <Button
              variant="danger"
              loading={disconnectMutation.isLoading}
              clickCountDownMs={COUNT_DOWN_MS}
              disabled={!checkboxChecked || disconnectMutation.isLoading}
              onClick={handleDisconnect}
            >
              {t("cdh.unification.disconnect.modal.confirm")}
            </Button>
          </Stack>
        }
      >
        <>
          <Typography variant="body">
            {t("cdh.unification.disconnect.modal.content.paragraph1")}
          </Typography>
          <DiscList
            css={css({
              color: theme.colors.neutral100,
              fontSize: "0.875rem",
            })}
            items={t("cdh.unification.disconnect.modal.content.paragraph2")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxChecked}
                onChange={handleCheckboxChange}
              />
            }
            label={
              <Typography variant="body">
                {t("cdh.unification.disconnect.modal.content.consensus")}
              </Typography>
            }
          />
        </>
      </Modal>
      <Modal
        title={t("cdh.unification.disconnectFailed.modal.title")}
        open={openFailed}
        handleClose={cleanup}
        footer={
          <Stack alignItems="flex-end">
            <Button variant="primary" onClick={cleanup}>
              {t("common.understood")}
            </Button>
          </Stack>
        }
      >
        <Typography variant="body">
          {t("cdh.unification.disconnectFailed.modal.content")}
        </Typography>
      </Modal>
    </div>
  );
};
