import { preload } from "@chatbotgang/etude/dom/preload";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
// eslint-disable-next-line no-restricted-imports -- For inheriting props
import type { FallbackProps } from "react-error-boundary";
import { Trans } from "react-i18next";

import { Typography } from "@/components/Typography";
import { logError } from "@/features/logger/index";
import img from "@/layout/ErrorBoundary/ErrorFallbackPage/error.png";
import { ReloadButton } from "@/layout/ErrorBoundary/ErrorFallbackPage/ReloadButton";

const maxImageWidth = "304px";

const cssContainer = css`
  max-width: 789px;
  display: flex;
  gap: 4em;
  align-items: center;
  justify-content: space-between;
  margin: 10em auto;
`;

const cssMain = css`
  display: flex;
  overflow: hidden;
  width: fit-content;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
  justify-items: center;
`;

const cssAction = css`
  display: flex;
  margin: 20px 0;
  place-items: center center;

  > button {
    width: clamp(4em, 229px, 100%);
  }
`;

const cssImage = css`
  display: flex;
  min-width: 6em;
  max-width: calc(100% - ${maxImageWidth} - 4em);
`;

const cssTitle = css`
  font-size: 29px;
  font-weight: 500;
`;

const Title = memo(function Title({
  children,
  ...props
}: ComponentProps<"h1">) {
  const titleNode = useMemo(
    () =>
      children ?? (
        <Trans i18nKey="errorPage.title">Oops ! Something’s wrong here.</Trans>
      ),
    [children],
  );
  return (
    <h1 css={cssTitle} {...props}>
      {titleNode}
    </h1>
  );
});

type ContentProps = {
  children?: ReactNode;
};

const Content = memo(function Content({ children }: ContentProps) {
  const content = useMemo(
    () =>
      children || (
        <Typography variant="body">
          <Trans i18nKey="errorPage.content.default">
            Don’t worry, we're trying to figure out the issue.
          </Trans>
        </Typography>
      ),
    [children],
  );
  return content;
});

export type ErrorFallbackPageProps = {
  title?: ReactNode;
  content?: ReactNode;
  reloadWindow?: boolean;
  className?: string;
} & FallbackProps &
  ComponentProps<"div">;

// Preload the image that make it able to display when disconnected.
preload({ href: img, as: "image" });

export const ErrorFallbackPage = memo(function ErrorFallbackPage({
  title,
  content,
  resetErrorBoundary,
  reloadWindow = false,
  error,
  ...props
}: ErrorFallbackPageProps) {
  const [eventId, setEventId] = useState("");

  const reload = useHandler(() => {
    if (reloadWindow || !resetErrorBoundary) {
      window.location.reload();
      return;
    }
    try {
      resetErrorBoundary();
    } catch (e) {
      // Record error and do nothing because ErrorBoundary should be the last error guard.
      logError(e);
    }
  });

  useEffect(
    function generateErrorEventId() {
      setEventId(!error ? "" : logError(error));
    },
    [error],
  );

  return (
    <div css={cssContainer} {...props}>
      <div css={cssMain}>
        <Title children={title} />
        <Content children={content} />
        {eventId ? (
          <Typography variant="body">{`Error ID: ${eventId}`}</Typography>
        ) : null}
        {reloadWindow ? (
          <div css={cssAction}>
            <ReloadButton onClick={reload} variant="primary">
              <Trans i18nKey="errorPage.action.reload">Reload</Trans>
            </ReloadButton>
          </div>
        ) : null}
      </div>
      <img css={cssImage} src={img} alt="Error" />
    </div>
  );
});
