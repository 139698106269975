import { memo } from "@chatbotgang/etude/react/memo";
import { ThemeProvider } from "@mui/material/styles";
import { muiTheme } from "@polifonia/theme";

export const MuiThemeProvider = memo(function MuiThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;
});
