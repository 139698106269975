import { fc } from "@chatbotgang/etude/react/fc";
import { css, type SerializedStyles } from "@emotion/react";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// eslint-disable-next-line no-restricted-imports -- this is the only place we import from @mui/material
import type { ModalProps as MuiModalProps } from "@mui/material/Modal";
// eslint-disable-next-line no-restricted-imports -- -- this is the only place we import from @mui/material
import MuiModal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { theme } from "@polifonia/theme";
import type { FC } from "react";

import { ExclamationIcon } from "@/components/Modal/ExclamationIcon";

type MuiModalPropsOnClose = NonNullable<MuiModalProps["onClose"]>;

export interface ModalProps extends MuiModalProps {
  handleClose: (
    event: Parameters<MuiModalPropsOnClose>[0],
    reason: Parameters<MuiModalPropsOnClose>[1] | "closeIconClick",
  ) => void;
  width?: number;
  footer?: React.ReactNode;
}

export const ExtendedModal = fc<ModalProps>(function Modal({
  open,
  title,
  handleClose = () => {},
  width = 520,
  children,
  footer,
  style = {},
  ...restProps
}) {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      style={style}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...restProps}
    >
      <Box
        sx={{
          padding: 0,
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.colors.white,
          width,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"flex-start"}
          sx={{
            minHeight: "56px",
            padding: "1rem 24px",
          }}
        >
          <Box
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            {title}
          </Box>
          <IconButton
            size="small"
            onClick={(e) => {
              handleClose(e, "closeIconClick");
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Divider />
        <Box
          sx={{
            padding: "24px",
            overflowY: "auto",
            maxHeight: "75vh",
          }}
        >
          {children}
        </Box>
        {footer ? (
          <Box
            sx={{
              padding: "1rem",
            }}
          >
            {footer}
          </Box>
        ) : null}
      </Box>
    </MuiModal>
  );
});

const Warning = (() => {
  const styles = {
    content: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      text-align: center;

      > :first-child {
        display: flex;
        width: min(100%, 110px);
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;

        > * {
          overflow: hidden;
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
        }
      }
    `,
  } satisfies Record<string, SerializedStyles>;
  const Warning: FC<ModalProps> = (props) => {
    return (
      <ExtendedModal {...props}>
        <div css={styles.content}>
          <div>
            <ExclamationIcon
              css={css`
                display: flex;
                width: 110px;
                height: 110px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: ${theme.colors.yellow020};
                color: ${theme.colors.yellow060};
                font-size: 65px;
              `}
            />
          </div>
          <div>{props.children}</div>
        </div>
      </ExtendedModal>
    );
  };
  return Warning;
})();

const Modal = Object.assign(ExtendedModal, { Warning });

export { Modal };
